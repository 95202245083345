import React, { useState ,useContext} from "react";
import { TFOIcon,  LogoImage } from "../../icons";
import "./Login.css";
import {
  Spinner,
  Alert,
  Row,
  Col,
  Container,
} from "react-bootstrap";

import api from "../../api";
import { useHistory } from "react-router";
// OTP Verification Component
import VerifyOtp from "./VerifyOtp";
import LandingPage from "../LandingPage/LandingPage";
import { Context } from "../Wrapper/Wrapper";
export default function Login(props) {
  const context = useContext(Context);
  const history = useHistory();
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpUserEmail, setOtpUserEmail] = useState("");
  const [loading, setLoading] = useState();
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [submit, setSubmit] = useState(false); // To check when email ID is subitted.
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState('');


  const sendOtp = async (e) => {
    try {
      setLoading(true);
      await api.post(process.env.REACT_APP_OTP_ENDPOINT,
        {
          "operation": "send",
          "channel": "email",
          "email": email
        }
      );
      setSubmit(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  // const submitForm = async (event) =>{
  //   event.preventDefault();
  //   sendOtp();
  // }
  
  const submitForm =  async (event) =>{
    
    event.preventDefault();
    setSubmit(true)
    context.setIsAuthenticated(true);
    context.setUserEmail(email);
    sessionStorage.setItem("otpValidated",true)
    sessionStorage.setItem("otpUserEmail",email)
    history.goBack();
  }
  
  const handleInputChangeEmail = async (event) => {
    setErrorMessage("");
    setEmail(event.target.value);

    if (email === "") {
      setValidatedEmail(false);
    } else {
      setValidatedEmail(true);
    }

  };

  return (
    <>
      <header className="login-nav">
        <Container fluid>
          <Row>
            <Col>
              <TFOIcon width="114px" height="50px" />
            </Col>
            <Col md="auto" style={{ marginTop: "15px" }}>
              {/* <HelpIcon />  */}
            </Col>
          </Row>
        </Container>
      </header>
      <main className="login">
        <section className="content">
          <div className="logo">
            <LogoImage />
          </div>
          {!submit ?
            (
              <>
                <h2 className="login-header">Please Enter Email</h2>
                <form onSubmit={submitForm}>
                  <div className="login-form">
                    <div className="form-group">
                      <input
                        required
                        className="form-control"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={handleInputChangeEmail}
                      // name="email"
                      />
                    </div>

                  </div>
                  {errorMessage !=="" && (
                    <div className="errorContainer">
                      <Alert>{errorMessage}</Alert>
                    </div>
                  )}

                  <div className="login-actions">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-block"
                        disabled={!validatedEmail}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                {/* <VerifyOtp email={email} /> */}
                <LandingPage/>
              </>
            )
          }
        </section>
        {loading && (
          <>
            <section className="app-loader">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </section>
            <div className="overlay"></div>
          </>
        )}
      </main>
    </>
  );
}
