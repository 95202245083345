import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import Arabic from "../../lang/ar.json";
import English from "../../lang/en.json";

const Context = React.createContext();

const local = navigator.language || "en";

let lang;
if (local === "ar") {
  lang = Arabic;
} else {
  lang = English;
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  const [isClient, setClient] = useState(false);
  const [rmName, setRMName] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail,setUserEmail] = useState("");

  function selectLanguage(newLocale) {
    setLocale(newLocale);
    if (newLocale === "ar") {
      setMessages(Arabic);
      document.body.setAttribute("class", "body-rtl")
    } else {
      setMessages(English);
      document.body.setAttribute("class", "")
    }
  }

  return (
    <Context.Provider 
      value={{ locale, 
        selectLanguage,
        isClient,
        setClient, 
        rmName, 
        setRMName, 
        isAuthenticated, 
        setIsAuthenticated,
        userEmail,
        setUserEmail,
        }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
export { Context };
