import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import { InputGroup, FormControl, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import "./suitability.css";
import { Row, Col } from "react-bootstrap";
import "rc-slider/assets/index.css";
import Summery from "./summery/Summery";
import { LeftArrow } from "../../icons";
import { Field, useFormik } from "formik";

import * as Yup from "yup";
import { getErrorRefToScrollIntoView } from "../../utils";

const genError = () => {
  return (
    <FormattedMessage id="error.gen" defaultMessage="This field is required" />
  );
};

const nonClientSchema = Yup.object().shape({
  ques1: Yup.string().required(genError),
  ques1Other: Yup.string().when("ques1", {
    is: "other",
    then: Yup.string().required(genError),
  }),
  ques2: Yup.string().required(genError),
  ques2Other: Yup.string().when("ques2", {
    is: "other",
    then: Yup.string().required(genError),
  }),
  ques3: Yup.string().required(genError),
  ques4: Yup.string().required(genError),
  ques5: Yup.string().required(genError),
  ques5Year1: Yup.string().when("ques5", {
    is: "yes",
    then: Yup.string().when(["ques5Year2"], {
      is: (ques5Year2) => !ques5Year2,
      then: Yup.string().required(() => (
        <FormattedMessage
          id="q.five.error"
          defaultMessage="This field is required"
        />
      )),
    }),
  }),
  ques5Year2: Yup.string().when("ques5", {
    is: "yes",
    then: Yup.string().when(["ques5Year1"], {
      is: (ques5Year1) => !ques5Year1,
      then: Yup.string().required(() => (
        <FormattedMessage
          id="q.five.error"
          defaultMessage="This field is required"
        />
      )),
    }),
  }),

  ques6: Yup.string().required(genError),
  ques6Year1: Yup.string().when("ques6", {
    is: "yes",
    then: Yup.string().when(["ques6Year2"], {
      is: (ques6Year2) => !ques6Year2,
      then: Yup.string().required(() => (
        <FormattedMessage
          id="q.six.error"
          defaultMessage="This field is required"
        />
      )),
    }),
  }),
  ques6Year2: Yup.string().when("ques6", {
    is: "yes",
    then: Yup.string().when(["ques6Year1"], {
      is: (ques6Year1) => !ques6Year1,
      then: Yup.string().required(() => (
        <FormattedMessage
          id="q.six.error"
          defaultMessage="This field is required"
        />
      )),
    }),
  }),

  ques7: Yup.string().when("ques2", {
    is: "q.two.ans.one",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required(genError),
  }),
  ques7Amt: Yup.string().when("ques7", {
    is: "yes",
    then: Yup.string().required(() => (
      <FormattedMessage id="error.gen" defaultMessage="error.gen" />
    )),
  }),
});

const clientSchema = Yup.object().shape({
  ques1: Yup.string().required(genError),
  ques1Other: Yup.string().when("ques1", {
    is: "other",
    then: Yup.string().required(genError),
  }),
  ques2: Yup.string().required(genError),
  ques2Other: Yup.string().when("ques2", {
    is: "other",
    then: Yup.string().required(genError),
  }),
  ques3: Yup.string().required(genError),
  ques5immediate: Yup
.string()
.test(
'totalShouldbeEqual',
<FormattedMessage id="error.client5th" defaultMessage="error.client5th" />,
function(ques5immediate) {
return (convertToNumber(this.parent.ques4) == convertToNumber(ques5immediate) + convertToNumber(this.parent.ques5yr1) + convertToNumber(this.parent.ques5yr2))
}
),

  ques5yr1: Yup
.string()
.test(
'totalShouldbeEqual',
<FormattedMessage id="error.client5th" defaultMessage="error.client5th" />,
function(ques5yr1) {
return (convertToNumber(this.parent.ques4) == convertToNumber(this.parent.ques5immediate) + convertToNumber(ques5yr1) + convertToNumber(this.parent.ques5yr2))
}
),

  ques5yr2: Yup
.string()
.test(
'totalShouldbeEqual',
<FormattedMessage id="error.client5th" defaultMessage="error.client5th" />,
function(ques5yr2) {
return (convertToNumber(this.parent.ques4) == convertToNumber(this.parent.ques5immediate) + convertToNumber(this.parent.ques5yr1) + convertToNumber(ques5yr2))
}
),
  ques6: Yup.string().required(genError),
  ques6Year1: Yup.string().when("ques6", {
    is: "yes",
    then: Yup.string().when(["ques6Year2"], {
      is: (ques6Year2) => !ques6Year2,
      then: Yup.string().required(() => (
        <FormattedMessage
          id="q.six.error"
          defaultMessage="This field is required"
        />
      )),
    }),
  }),
  ques6Year2: Yup.string().when("ques6", {
    is: "yes",
    then: Yup.string().when(["ques6Year1"], {
      is: (ques6Year1) => !ques6Year1,
      then: Yup.string().required(() => (
        <FormattedMessage
          id="q.six.error"
          defaultMessage="This field is required"
        />
      )),
    }),
  }),
  ques7: Yup.string().when("ques2", {
    is: "q.two.ans.one",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required(genError),
  }),
  ques7Amt: Yup.string().when("ques7", {
    is: "yes",
    then: Yup.string().required(() => (
      <FormattedMessage id="error.gen" defaultMessage="error.gen" />
    )),
  }),
});

const nonSchemaErrorsInitial = {
  withdrawalGreaterThanImmediateError: false,
  withdrawalGreaterThanFundingError: false,
  maxAmtPercentToInvestment: false,
  investAmtNotSufficient: false,
  investAmtNotSufficient6thquestionyear1: false,
  investAmtNotSufficient6thquestionyear2: false,
};

const isOneOfNumberFields = (name) => {
  const numberFields = [
    "ques4",
    "ques5Year1",
    "ques5Year2",
    "ques5immediate",
    "ques5yr1",
    "ques5yr2",
    "ques6Year1",
    "ques6Year2",
    "ques7Amt",
  ];
  if (numberFields.indexOf(name) >= 0) {
    return true;
  } else {
    return false;
  }
};

const convertToNumber = (value) => {
  let numberValue = 0;
  if (value) {
    if (value === "0") {
      numberValue = 0;
    } else {
      numberValue = value.toString().replace(/[^0-9]/g, "") - 0;
    }
  }
  return Math.round(numberValue);
};

const formatNumbers = (value) => {
  let formatted = value;
  if (value || value === "0") {
    formatted = new Intl.NumberFormat().format(
      parseFloat(value.toString().replace(/,/g, ""))
    );
  }
  return formatted;
};

const formatNumbersixth = (value) => {
  if (convertToNumber(value) < 5000) {
    return convertToNumber(0);
  } else if (convertToNumber(value) >= 100000) {
    return Math.round(convertToNumber(value) / 1000) * 1000;
  } else if (
    convertToNumber(value) < 100000 &&
    convertToNumber(value) >= 5000
  ) {
    return Math.round(convertToNumber(value) / convertToNumber(value)) * 100000;
  }
};
const MyPortfolio = forwardRef((props,ref)=>{
  const intl = useIntl();
  const { isClient } = props;
  const currentTabName = "My Portfolio";
  const { handleActiveTab, handleInvestAmount, formikValues, setFormValues } =
    props;
    const  MyPortfolios = JSON.parse(sessionStorage.getItem('MyPortfolios'))
  const currentTabVals = formikValues[currentTabName];
  const [selected, setSelected] = useState({
    qOne: "",
    qTwo: "",
    qThree: "",
    qFive: "",
    qSix: "",
    qSeven: "",
  });
  const [remaining, setRemaining] = useState(0);
  const [remaining1, setRemaining1] = useState(0);
  const [percentFunded, setPercentFunded] = useState(0.0);
  const [nonSchemaErrors, setNonSchemaErrors] = useState(
    nonSchemaErrorsInitial
  );

  const scrollToTop = () => {
    let temp = document.getElementsByClassName("main-container");
    if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  };
  useEffect(() => {
    let temp = document.getElementsByClassName("main-container");
    if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSelected({
      qOne: formikValues[currentTabName].ques1 === "q.one.ans.one" ? "1" : "",
      qTwo: formikValues[currentTabName].ques2 === "q.two.ans.one" ? "1" : "",
      qFive: formikValues[currentTabName].ques5 === "yes" ? "1" : "",
      qSix: formikValues[currentTabName].ques6 === "yes" ? "1" : "",
      qSeven: formikValues[currentTabName].ques7 === "yes" ? "1" : "",
    });
    let calculation =
      convertToNumber(formikValues[currentTabName].ques4) -
      convertToNumber(formikValues[currentTabName].ques6Year2) -
      convertToNumber(formikValues[currentTabName].ques6Year1);

    if (isClient) {
      calculation = formikValues[currentTabName].ques5immediate;
    }
    setRemaining(formatNumbers(calculation + ""));

    const calculation1 =
      convertToNumber(formikValues[currentTabName].ques4) -
      convertToNumber(formikValues[currentTabName].ques5immediate) -
      convertToNumber(formikValues[currentTabName].ques5yr1) -
      convertToNumber(formikValues[currentTabName].ques5yr2);
    setRemaining1(formatNumbers(calculation1 + ""));

    const percent =
      (convertToNumber(formikValues[currentTabName].ques7Amt) * 100) /
      convertToNumber(formikValues[currentTabName].ques4);
    isNaN(percent)
      ? setPercentFunded("0")
      : setPercentFunded(percent.toFixed(2));
  }, [formikValues[currentTabName]]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ques1: MyPortfolios && MyPortfolios.ques1 ? MyPortfolios.ques1:currentTabVals.ques1,
      ques1Other: MyPortfolios && MyPortfolios.ques1Other ? MyPortfolios.ques1Other:currentTabVals.ques1Other,
      ques2:MyPortfolios && MyPortfolios.ques2 ?MyPortfolios.ques2 : currentTabVals.ques2,
      ques2Other: MyPortfolios && MyPortfolios.ques2Other ? MyPortfolios.ques2Other:currentTabVals.ques2Other,
      ques3:MyPortfolios && MyPortfolios.ques3?MyPortfolios.ques3: currentTabVals.ques3,
      ques4: MyPortfolios && MyPortfolios.ques4? MyPortfolios.ques4:currentTabVals.ques4,
      ques5: MyPortfolios && MyPortfolios.ques5 ? MyPortfolios.ques5:currentTabVals.ques5,
      ques5Year1: MyPortfolios && MyPortfolios.ques5Year1 ? MyPortfolios.ques5Year1:currentTabVals.ques5Year1,
      ques5immediate: MyPortfolios && MyPortfolios.ques5immediate?MyPortfolios.ques5immediate:currentTabVals.ques5immediate,
      ques5yr1: MyPortfolios && MyPortfolios.ques5yr1? MyPortfolios.ques5yr1:currentTabVals.ques5yr1,
      ques5yr2: MyPortfolios && MyPortfolios.ques5yr2 ? MyPortfolios.ques5yr2:currentTabVals.ques5yr2,
      ques5Year2: MyPortfolios && MyPortfolios.ques5Year2 ? MyPortfolios.ques5Year2:currentTabVals.ques5Year2,
      ques6: MyPortfolios && MyPortfolios.ques6 ? MyPortfolios.ques6:currentTabVals.ques6,
      ques6Year1: MyPortfolios && MyPortfolios.ques6Year1 ? MyPortfolios.ques6Year1:currentTabVals.ques6Year1,
      ques6Year2: MyPortfolios && MyPortfolios.ques6Year2 ? MyPortfolios.ques6Year2 : currentTabVals.ques6Year2,
      ques7: MyPortfolios && MyPortfolios.ques7 ? MyPortfolios.ques7 : currentTabVals.ques7,
      ques7Amt: MyPortfolios && MyPortfolios.ques7Amt ? MyPortfolios.ques7Amt : currentTabVals.ques7Amt,
    },
    validationSchema: isClient ? clientSchema : nonClientSchema,
    onSubmit: (values) => {
      sessionStorage.setItem("MyPortfolios",JSON.stringify(values))
      if (nonSchemaValidationsPassing()) {
        if (isClient) {
          if (+remaining1 === 0) {
            setFormValues(values);
            handleActiveTab("Portfolio Preferences");
          }
        } else {
          setFormValues(values);
          handleActiveTab("Portfolio Preferences");
        }
      }
    },
    validateOnMount:true
  });
  useImperativeHandle(ref,()=>({ 
    validateMyportfolio:()=>{
       return {
         ...formik
       }
      },
    submitMyportfolio:()=>{
      return formik.submitForm();
    },
    touchedMyportfolio:()=>{
      formik.setFieldTouched("ques1", true);
      formik.setFieldTouched("ques1Other", true);
      formik.setFieldTouched("ques2", true);
      formik.setFieldTouched("ques2Other", true);
      formik.setFieldTouched("ques3", true);
      formik.setFieldTouched("ques4", true);
      formik.setFieldTouched("ques5", true);
      formik.setFieldTouched("ques5Year1", true);
      formik.setFieldTouched("ques5immediate", true);
      formik.setFieldTouched("ques5yr1", true);
      formik.setFieldTouched("ques5yr2", true);
      formik.setFieldTouched("ques5Year2", true);
      formik.setFieldTouched("ques6", true);
      formik.setFieldTouched("ques6Year1", true);
      formik.setFieldTouched("ques6Year2", true);
      formik.setFieldTouched("ques7", true);
      formik.setFieldTouched("ques7Amt", true);  
    }
  }))
  useEffect(() => {
    if (formik.isSubmitting) {
      let errors = Object.keys(formik.errors);
      if (errors.length > 0) {
        getErrorRefToScrollIntoView(errors[0]);
      } else {
        for (let i in nonSchemaErrors) {
          if (nonSchemaErrors[i]) {
            switch (i) {
              case "withdrawalGreaterThanImmediateError": {
                getErrorRefToScrollIntoView("ques6Year1");
                return;
              }
              case "maxAmtPercentToInvestment": {
                getErrorRefToScrollIntoView("ques7Amt");
                return;
              }
              case "investAmtNotSufficient": {
                getErrorRefToScrollIntoView("ques4");
                return;
              }
              case "investAmtNotSufficient6thquestionyear1": {
                getErrorRefToScrollIntoView("ques6Year1");
                return;
              }
              case "investAmtNotSufficient6thquestionyear2": {
                getErrorRefToScrollIntoView("ques6Year2");
                return;
              }
              default:
                return;
            }
          }
        }
      }
    }
  }, [formik.isSubmitting]);

  const setFormikValue = (e) => {
    if (
      e.target.name === "ques7Amt" &&
      formik.values.ques4 &&
      formik.values.ques4 !== "0"
    ) {
      const percent =
        (convertToNumber(e.target.value) * 100) /
        convertToNumber(formik.values.ques4);
      if (percent > 7.5) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          maxAmtPercentToInvestment: true,
        });
      } else {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          maxAmtPercentToInvestment: false,
        });
      }
      formik.setFieldValue("question7percentage", percent.toFixed(2));
      setPercentFunded(percent.toFixed(2));
    }
    if (
      e.target.name === "ques4" &&
      formik.values.ques7Amt &&
      formik.values.ques7Amt !== "0"
    ) {
      const percent =
        (convertToNumber(formik.values.ques7Amt) * 100) /
        convertToNumber(e.target.value);
      if (percent !== Infinity) {
        if (percent > 7.5) {
          setNonSchemaErrors({
            ...nonSchemaErrors,
            maxAmtPercentToInvestment: true,
          });
        } else {
          setNonSchemaErrors({
            ...nonSchemaErrors,
            maxAmtPercentToInvestment: false,
          });
        }
        setPercentFunded(percent.toFixed(2));
      } else {
        setPercentFunded(0);
      }
    }
    if (isOneOfNumberFields(e.target.name) && e.target.value === "0") {
      formik.setFieldValue(e.target.name, "");
    } else {
      if (isOneOfNumberFields(e.target.name)) {
        const filteredValues = formatNumbers(
          e.target.value.replace(/[^0-9/,]/g, "")
        );
        formik.setFieldValue(e.target.name, filteredValues);
      } else {
        formik.setFieldValue(e.target.name, e.target.value);
      }
    }
  };

  const ques6OptionChanges = (e) => {
    setFormikValue(e);
    let calculation = formik.values.ques4;
    if (e.target.name === "ques6Year1") {
      calculation =
        convertToNumber(calculation) -
        convertToNumber(formik.values.ques6Year2) -
        convertToNumber(e.target.value);
      if (isClient) {
        calculation =
          convertToNumber(formik.values.ques5immediate) -
          convertToNumber(e.target.value) -
          convertToNumber(formik.values.ques6Year2);
      }
    }

    if (e.target.name === "ques6Year2") {
      calculation =
        convertToNumber(calculation) -
        convertToNumber(e.target.value) -
        convertToNumber(formik.values.ques6Year1);
      if (isClient) {
        calculation =
          convertToNumber(formik.values.ques5immediate) -
          convertToNumber(e.target.value) -
          convertToNumber(formik.values.ques6Year1);
      }
    }
    if (
      (formik.values.ques6Year2 >= 0 && formik.values.ques6Year2) ||
      (formik.values.ques6Year1 >= 0 && formik.values.ques6Year1)
    ) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient6thquestionyear1: false,
        investAmtNotSufficient6thquestionyear2: false,
      });
    }
    if (formik.values.ques6Year2 == 0 && formik.values.ques6Year1 == 0) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient6thquestionyear1: true,
        investAmtNotSufficient6thquestionyear2: true,
      });
    }
    if (calculation < 0) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        withdrawalGreaterThanImmediateError: true,
      });
      setRemaining(
        <span className="validation-error-amount">
          {formatNumbers(calculation + "")}
        </span>
      );
       console.log("nonSchemaErrors----",nonSchemaErrors);
    } else {
      if (nonSchemaErrors.withdrawalGreaterThanImmediateError) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          withdrawalGreaterThanImmediateError: false,
        });
      }
      setRemaining(formatNumbers(calculation + ""));
    }
  };
  const ques5OptionChanges = (e) => {
    setFormikValue(e);
    let calculation1 = formik.values.ques4;
    if (e.target.name === "ques5immediate") {
      calculation1 =
        convertToNumber(calculation1) -
        convertToNumber(formik.values.ques5yr1) -
        convertToNumber(formik.values.ques5yr2) -
        convertToNumber(e.target.value);
    }
    if (e.target.name === "ques5yr1") {
      calculation1 =
        convertToNumber(calculation1) -
        convertToNumber(formik.values.ques5immediate) -
        convertToNumber(formik.values.ques5yr2) -
        convertToNumber(e.target.value);
    }
    if (e.target.name === "ques5yr2") {
      calculation1 =
        convertToNumber(calculation1) -
        convertToNumber(formik.values.ques5immediate) -
        convertToNumber(formik.values.ques5yr1) -
        convertToNumber(e.target.value);
    }
    if (calculation1 < 0) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        withdrawalGreaterThanFundingError: true,
      });
      setRemaining1(
        <span className="validation-error-amount">
          {formatNumbers(calculation1 + "")}
        </span>
      );
    } else {
      if (nonSchemaErrors.withdrawalGreaterThanFundingError) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          withdrawalGreaterThanFundingError: false,
        });
      }
      setRemaining1(formatNumbers(calculation1 + ""));
    }
  };

  const setQues6OptionsToNull = () => {
    formik.setFieldValue("ques6Year1", "");
    formik.setFieldValue("ques6Year2", "");
  };
  const setQues5OptionsToNull = () => {
    formik.setFieldValue("ques5yr1", "");
    formik.setFieldValue("ques5yr2", "");
    formik.setFieldValue("ques5immediate", "");
  };

  const nonSchemaValidationsPassing = () => {
    for (let i in nonSchemaErrors) {
      if (nonSchemaErrors[i]) {
        return false;
      }
    }
    return true;
  };
  const setSixthQuestionYear1 = (e) => {
    let { name, value } = e.target;
    value = formatNumbers(formatNumbersixth(value));
    let calculation =
      convertToNumber(formik.values.ques4) -
      convertToNumber(formik.values.ques6Year2) -
      convertToNumber(value);
    if (isClient) {
      calculation =
        convertToNumber(formik.values.ques5immediate) -
        convertToNumber(value) -
        convertToNumber(formik.values.ques6Year2);
    }
    if (calculation < 0) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        withdrawalGreaterThanImmediateError: true,
      });
      setRemaining(
        <span className="validation-error-amount">
          {formatNumbers(calculation + "")}
        </span>
      );
    } else {
      if (nonSchemaErrors.withdrawalGreaterThanImmediateError) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          withdrawalGreaterThanImmediateError: false,
        });
      }
      setRemaining(formatNumbers(calculation + ""));
    }
    if (value < 100000 && convertToNumber(formik.values.ques6Year2) > 100000) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient6thquestionyear1: true,
      });
    } else {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient6thquestionyear1: false,
      });
    }
    if (value === 0) {
      formik.setFieldValue("ques6Year1", "");
    } else {
      formik.setFieldValue(name, value);
    }
  };
  const setSixthQuestionYear2 = (e) => {
    let { name, value } = e.target;
    value = formatNumbers(formatNumbersixth(value));
    formik.setFieldValue(name, value);
    let calculation =
      convertToNumber(formik.values.ques4) -
      convertToNumber(value) -
      convertToNumber(formik.values.ques6Year1);
    if (isClient) {
      calculation =
        convertToNumber(formik.values.ques5immediate) -
        convertToNumber(value) -
        convertToNumber(formik.values.ques6Year1);
    }
    if (calculation < 0) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        withdrawalGreaterThanImmediateError: true,
      });
      setRemaining(
        <span className="validation-error-amount">
          {formatNumbers(calculation + "")}
        </span>
      );
    } else {
      if (nonSchemaErrors.withdrawalGreaterThanImmediateError) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          withdrawalGreaterThanImmediateError: false,
        });
      }
      setRemaining(formatNumbers(calculation + ""));
    }
    if (value < 100000 && convertToNumber(formik.values.ques6Year1) > 100000) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient6thquestionyear2: true,
      });
    } else {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient6thquestionyear2: false,
      });
    }
    if (value === 0) {
      formik.setFieldValue("ques6Year2", "");
    } else {
      formik.setFieldValue(name, value);
    }
  };
  const setFourthQuestion = (e) => {
    let { name, value } = e.target;
    value = formatNumbers(
      Math.round(convertToNumber(value, 10) / 10000) * 10000
    );
    if (!isClient) {
      setRemaining(value);
    }

    formik.setFieldValue(name, value);
    if (convertToNumber(value) < 300000) {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient: true,
      });
    } else {
      setNonSchemaErrors({
        ...nonSchemaErrors,
        investAmtNotSufficient: false,
      });
    }
  };
  const setFifthoneQuestion = (e) => {
    let { name, value } = e.target;

    formik.setFieldValue(
      name,
      formatNumbers(Math.round(convertToNumber(value, 10) / 10000) * 10000)
    );
    if (isClient) {
      let calculation1 = formik.values.ques4;

      if (e.target.name === "ques5immediate") {
        calculation1 =
          convertToNumber(calculation1) -
          convertToNumber(formik.values.ques5yr1) -
          convertToNumber(formik.values.ques5yr2) -
          Math.round(convertToNumber(value, 10) / 10000) * 10000;
        setRemaining(
          formatNumbers(Math.round(convertToNumber(value, 10) / 10000) * 10000)
        );
      }
      if (e.target.name === "ques5yr1") {
        calculation1 =
          convertToNumber(calculation1) -
          convertToNumber(formik.values.ques5immediate) -
          convertToNumber(formik.values.ques5yr2) -
          convertToNumber(e.target.value);
      }
      if (e.target.name === "ques5yr2") {
        calculation1 =
          convertToNumber(calculation1) -
          convertToNumber(formik.values.ques5immediate) -
          convertToNumber(formik.values.ques5yr1) -
          convertToNumber(e.target.value);
      }
      if (calculation1 < 0) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          withdrawalGreaterThanFundingError: true,
        });
        setRemaining1(
          <span className="validation-error-amount">
            {formatNumbers(calculation1 + "")}
          </span>
        );
      } else {
        if (nonSchemaErrors.withdrawalGreaterThanFundingError) {
          setNonSchemaErrors({
            ...nonSchemaErrors,
            withdrawalGreaterThanFundingError: false,
          });
        }
        setRemaining1(calculation1);
      }
    }
  };
  return (
    <div>
    {/* {JSON.stringify(formik.errors)} */}
      {isClient ? (
        <div>
          <p>
            <FormattedMessage id="q.top1" defaultMessage="q.top1" />
          </p>
          <p className="clienttopheader">
            <FormattedMessage id="q.top2" defaultMessage="q.top2" />
          </p>
        </div>
      ) : (
        <div></div>
      )}

      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.one" defaultMessage="q.one" />
          </p>
          <Row className="ans-row">
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques1"
                  defaultChecked={formik.values.ques1 === "q.one.ans.one"}
                  value="q.one.ans.one"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.one.ans.one"
                  defaultMessage="q.one.ans.one"
                />
              </label>
              {/* <label className="checkbox-wrapper">
              <input type="checkbox"  />
              <span className="checkbox-btn"></span>
              <FormattedMessage
                id="q.one.ans.one"
                defaultMessage="q.one.ans.one"
              />
            </label> */}
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques1"
                  defaultChecked={formik.values.ques1 === "q.one.ans.two"}
                  value="q.one.ans.two"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.one.ans.two"
                  defaultMessage="q.one.ans.two"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques1"
                  defaultChecked={formik.values.ques1 === "q.one.ans.three"}
                  value="q.one.ans.three"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.one.ans.three"
                  defaultMessage="q.one.ans.three"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper other-wrapper">
                <input
                  type="radio"
                  name="ques1"
                  defaultChecked={formik.values.ques1 === "other"}
                  value="other"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.ans.other"
                  defaultMessage="q.ans.other"
                />
                <input
                  type="text"
                  className="enter-text other-text customtextwidth"
                  placeholder={intl.formatMessage({
                    description: "q.ans.other",
                    id: "q.ans.other",
                  })}
                  name="ques1Other"
                  defaultValue={formik.values.ques1Other}
                  onChange={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qOne: "" } };
                    });
                  }}
                />
              </label>
            </Col>
          </Row>
          {formik.touched.ques1 && formik.errors.ques1 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques1}
            </div>
          ) : (
            ""
          )}
          {formik.touched.ques1Other && formik.errors.ques1Other ? (
            <div className="validation-error">{formik.errors.ques1Other}</div>
          ) : (
            ""
          )}
        </div>

        {/* Responsive Question 1 End */}

        {/* Responsive Question 2 Start */}
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.two" defaultMessage="q.two" />
          </p>
          <Row className="ans-row">
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques2"
                  defaultChecked={formik.values.ques2 === "q.two.ans.one"}
                  value="q.two.ans.one"
                  onChange={(e) => {
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwo: "1" } };
                    });
                    setFormikValue(e);
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.two.ans.one"
                  defaultMessage="q.two.ans.one"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques2"
                  defaultChecked={formik.values.ques2 === "q.two.ans.two"}
                  value="q.two.ans.two"
                  onChange={(e) => {
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwo: "2" } };
                    });
                    setFormikValue(e);
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.two.ans.two"
                  defaultMessage="q.two.ans.two"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques2"
                  defaultChecked={formik.values.ques2 === "q.two.ans.three"}
                  value="q.two.ans.three"
                  onChange={(e) => {
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwo: "3" } };
                    });
                    setFormikValue(e);
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.two.ans.three"
                  defaultMessage="q.two.ans.three"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper other-wrapper">
                <input
                  type="radio"
                  name="ques2"
                  defaultChecked={formik.values.ques2 === "other"}
                  value="other"
                  onChange={(e) => {
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwo: "" } };
                    });
                    setFormikValue(e);
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.ans.other"
                  defaultMessage="q.ans.other"
                />
                <input
                  type="text"
                  className="enter-text other-text customtextwidth"
                  placeholder={intl.formatMessage({
                    description: "q.ans.other",
                    id: "q.ans.other",
                  })}
                  name="ques2Other"
                  defaultValue={formik.values.ques2Other}
                  onChange={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwo: "" } };
                    });
                  }}
                />
              </label>
            </Col>
          </Row>
          {formik.touched.ques2 && formik.errors.ques2 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques2}
            </div>
          ) : (
            ""
          )}
          {formik.touched.ques2Other && formik.errors.ques2Other ? (
            <div className="validation-error">{formik.errors.ques2Other}</div>
          ) : (
            ""
          )}
        </div>
        {/* Responsive Question 2 End */}

        {/* Responsive Question 3 Start */}
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.three" defaultMessage="q.three" />
          </p>
          <Row className="ans-row">
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques3"
                  defaultChecked={formik.values.ques3 === "q.three.ans.one"}
                  value="q.three.ans.one"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.three.ans.one"
                  defaultMessage="q.three.ans.one"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques3"
                  defaultChecked={formik.values.ques3 === "q.three.ans.four"}
                  value="q.three.ans.four"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.three.ans.four"
                  defaultMessage="q.three.ans.four"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques3"
                  defaultChecked={formik.values.ques3 === "q.three.ans.two"}
                  value="q.three.ans.two"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.three.ans.two"
                  defaultMessage="q.three.ans.two"
                />
              </label>
            </Col>
            <Col xs={12} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  name="ques3"
                  defaultChecked={formik.values.ques3 === "q.three.ans.three"}
                  value="q.three.ans.three"
                  onChange={setFormikValue}
                />
                <span className="radio-btn"></span>
                <FormattedMessage
                  id="q.three.ans.three"
                  defaultMessage="q.three.ans.three"
                />
              </label>
            </Col>
          </Row>
          {formik.touched.ques3 && formik.errors.ques3 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques3}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Responsive Question 3 End */}

        {/* Responsive Question 4 Start */}
        <div className="question-wrapper">
          <p className="q-text">
            {isClient ? (
              <FormattedMessage
                id="q.four.client"
                defaultMessage="q.four.client"
              />
            ) : (
              <FormattedMessage id="q.four" defaultMessage="q.four" />
            )}
          </p>
          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <InputGroup className=" ">
                <FormControl
                  style={{ border: 0, color: "#f5f5f5" }}
                  className="textest"
                  type="text"
                  placeholder={intl.formatMessage({
                    description: "q.ans.enterAmount",
                    id: "q.ans.enterAmount",
                  })}
                  name="ques4"
                  aria-label="Amount (to the nearest dollar)"
                  onBlur={(e) => {
                    setFourthQuestion(e);
                  }}
                  onChange={(e) => {
                    setRemaining1(formatNumbers(e.target.value));
                    setQues6OptionsToNull();
                    setQues5OptionsToNull();
                    if (
                      convertToNumber(e.target.value) &&
                      convertToNumber(e.target.value) < 300000
                    ) {
                      setNonSchemaErrors({
                        ...nonSchemaErrors,
                        investAmtNotSufficient: true,
                      });
                    } else {
                      setNonSchemaErrors({
                        ...nonSchemaErrors,
                        investAmtNotSufficient: false,
                      });
                    }
                    setFormikValue(e);
                  }}
                  value={formik.values.ques4}
                />
                <InputGroup.Text style={{ border: 0 }} className="usd">
                  USD
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          {formik.touched.ques4 && formik.errors.ques4 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques4}
            </div>
          ) : (
            ""
          )}
          {nonSchemaErrors.investAmtNotSufficient ? (
            <div className="validation-error margintopn25">
              <FormattedMessage
                id="error.immediateamount"
                defaultMessage="error.immediateamount"
              ></FormattedMessage>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Responsive Question 4 End */}

        {/* Responsive Question 5 Start */}
        {isClient ? (
          <div
            className="question-wrapper"
            id={!formik.values.ques4 && "disableQuestion"}
          >
            <p className="q-text">
              <FormattedMessage
                id="q.five.client"
                defaultMessage="q.five.client"
              />
            </p>
            <Row className="ans-row">
              <Col xs={12} sm={"auto"} className="ans-col">
                <InputGroup className=" ">
                  <FormControl
                    style={{ border: 0, color: "#f5f5f5" }}
                    className="textest"
                    type="text"
                    value={formik.values.ques5immediate}
                    placeholder={intl.formatMessage({
                      description: "q.five.client.ans",
                      id: "q.five.client.ans",
                    })}
                    name="ques5immediate"
                    onChange={ques5OptionChanges}
                    aria-label="Amount (to the nearest dollar)"
                    onBlur={(e) => {
                      setFifthoneQuestion(e);
                    }}
                  />
                  <InputGroup.Text style={{ border: 0 }} className="usd">
                    USD
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col xs={12} sm={"auto"} className="ans-col ">
                <InputGroup className=" ">
                  <FormControl
                    style={{ border: 0, color: "#f5f5f5" }}
                    className="textest"
                    type="text"
                    value={formik.values.ques5yr1}
                    placeholder={intl.formatMessage({
                      description: "q.five.client.ans2",
                      id: "q.five.client.ans2",
                    })}
                    name="ques5yr1"
                    onChange={ques5OptionChanges}
                    aria-label="Amount (to the nearest dollar)"
                    onBlur={(e) => {
                      setFifthoneQuestion(e);
                    }}
                  />
                  <InputGroup.Text style={{ border: 0 }} className="usd">
                    USD
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col xs={12} sm={"auto"} className="ans-col marginbottom10">
                <InputGroup className=" ">
                  <FormControl
                    style={{ border: 0, color: "#f5f5f5" }}
                    className="textest"
                    type="text"
                    value={formik.values.ques5yr2}
                    placeholder={intl.formatMessage({
                      description: "q.five.client.ans3",
                      id: "q.five.client.ans3",
                    })}
                    name="ques5yr2"
                    onChange={ques5OptionChanges}
                    aria-label="Amount (to the nearest dollar)"
                  />
                  <InputGroup.Text style={{ border: 0 }} className="usd">
                    USD
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            {formik.values.ques4 && +remaining1 !== 0 && (
              <div
                className="remaining_amount_5th"
                id="Remaining_Amount"
              >
                <FormattedMessage
                  id="text.remainingamount"
                  defaultMessage="text.remainingamount"
                ></FormattedMessage>{" "}
                {remaining1}
              </div>
            )}
            { formik.errors.ques5yr1 || formik.errors.ques5yr2 || formik.errors.ques5immediate ? (
              <div className="validation-errorq5">{ formik.errors.ques5immediate}</div>
            ) : (
              ""
            )}
            {/* {formik.touched.ques5immediate && formik.errors.ques5immediate ? (
              <div className='validation-error margintopn25'>
                {formik.errors.ques5immediate}
              </div>
            ) : (
              ""
            )} */}
            {nonSchemaErrors.withdrawalGreaterThanFundingError ? (
              <div className="validation-error ">
                <FormattedMessage
                  id="error.fundingamount"
                  defaultMessage="error.fundingamount"
                ></FormattedMessage>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="question-wrapper">
            <p className="q-text">
              <FormattedMessage id="q.five" defaultMessage="q.five" />
            </p>
            <Row className="ans-row">
              <Col xs={12} sm={"auto"} className="ans-col">
                <label className="radio-wrapper">
                  <input
                    type="radio"
                    name="ques5"
                    value="yes"
                    defaultChecked={formik.values.ques5 === "yes"}
                    onClick={(e) => {
                      setFormikValue(e);
                      setSelected((prev) => {
                        return { ...prev, ...{ qFive: "1" } };
                      });
                    }}
                  />
                  <span className="radio-btn"></span>
                  <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
                </label>
              </Col>
              <Col xs={12} sm={"auto"} className="ans-col">
                <label className="radio-wrapper">
                  <input
                    type="radio"
                    name="ques5"
                    value="no"
                    defaultChecked={formik.values.ques5 === "no"}
                    onChange={(e) => {
                      formik.setFieldValue("ques5Year1", "");
                      formik.setFieldValue("ques5Year2", "");
                    }}
                    onClick={(e) => {
                      setFormikValue(e);
                      setSelected((prev) => {
                        return { ...prev, ...{ qFive: "2" } };
                      });
                    }}
                  />
                  <span className="radio-btn"></span>
                  <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
                </label>
              </Col>
            </Row>
            {selected.qFive == "1" ? (
              <div>
                <Row>
                  <Col
                    className="marginbottom10"
                    style={{ display: "flex", alignItems: "center" }}
                    xs={"auto"}
                    md={4}
                  >
                    <InputGroup className=" ">
                      <FormControl
                        style={{ border: 0, color: "#f5f5f5" }}
                        className="textest"
                        name="ques5Year1"
                        type="text"
                        value={formik.values.ques5Year1}
                        onChange={setFormikValue}
                        placeholder={intl.formatMessage({
                          description: "q.ans.aYear1",
                          id: "q.ans.aYear1",
                        })}
                        aria-label="Amount (to the nearest dollar)"
                      />
                      <InputGroup.Text style={{ border: 0 }} className="usd">
                        USD
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col
                    className="margintop10"
                    style={{ display: "flex", alignItems: "center" }}
                    xs={"auto"}
                    md={4}
                  >
                    <InputGroup className=" ">
                      <FormControl
                        style={{ border: 0, color: "#f5f5f5" }}
                        className="textest"
                        type="text"
                        name="ques5Year2"
                        value={formik.values.ques5Year2}
                        onChange={setFormikValue}
                        placeholder={intl.formatMessage({
                          description: "q.ans.aYear2",
                          id: "q.ans.aYear2",
                        })}
                        aria-label="Amount (to the nearest dollar)"
                      />
                      <InputGroup.Text style={{ border: 0 }} className="usd">
                        USD
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
                {formik.submitCount > 0 &&
                (formik.errors.ques5Year1 || formik.errors.ques5Year2) ? (
                  <div className="validation-errorq5">
                    {formik.errors.ques5Year1 || formik.errors.ques5Year2}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {formik.touched.ques5 && formik.errors.ques5 ? (
              <div className="validation-errorq5">{formik.errors.ques5}</div>
            ) : (
              ""
            )}
          </div>
        )}
        {/* Responsive Question 5 End */}

        {/* Responsive Question 6 Start */}
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.six" defaultMessage="q.six" />
          </p>
          <p>
            <span className="smallcontent">
              <FormattedMessage
                id="q.six.subtext"
                defaultMessage="q.six.subtext"
              />
            </span>
          </p>
          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques6 === "yes"}
                  name="ques6"
                  onChange={(e) => {
                    formik.setFieldValue("ques6Year1", "");
                    formik.setFieldValue("ques6Year2", "");
                  }}
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qSix: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques6 === "no"}
                  name="ques6"
                  onClick={(e) => {
                    setFormikValue(e);
                    setNonSchemaErrors({
                      ...nonSchemaErrors,
                      investAmtNotSufficient6thquestionyear1: false,
                    });
                    setNonSchemaErrors({
                      ...nonSchemaErrors,
                      investAmtNotSufficient6thquestionyear2: false,
                    });
                    setSelected((prev) => {
                      return { ...prev, ...{ qSix: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>
          {selected.qSix == "1" ? (
            <div>
              <Row style={{ marginBottom: "1.5rem" }}>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  xs={"auto"}
                  md={4}
                >
                  <FormattedMessage
                    id="q.six.liquidity"
                    defaultMessage="q.six.liquidity"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  xs={"auto"}
                  md={5}
                >
                  <InputGroup className=" ">
                    <Form.Label style={{ paddingTop: 5, marginRight: "20px" }}>
                      <FormattedMessage
                        id="q.six.year1"
                        defaultMessage="q.six.year1"
                      />
                    </Form.Label>
                    <FormControl
                      style={{ border: 0, color: "#f5f5f5" }}
                      className="textest"
                      type="text"
                      placeholder={intl.formatMessage({
                        description: "q.ans.enterAmount",
                        id: "q.ans.enterAmount",
                      })}
                      aria-label="Amount (to the nearest dollar)"
                      name="ques6Year1"
                      value={formik.values.ques6Year1}
                      onChange={(e) => {
                        ques6OptionChanges(e);
                        setFormikValue(e);
                      }}
                      onBlur={setSixthQuestionYear1}
                    />
                    <InputGroup.Text style={{ border: 0 }} className="usd">
                      USD
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col
                  className="margintop10"
                  style={{ display: "flex", alignItems: "center" }}
                  xs={"auto"}
                  md={5}
                >
                  <InputGroup className=" ">
                    <Form.Label style={{ paddingTop: 5 }}>
                      <FormattedMessage
                        id="q.six.year2"
                        defaultMessage="q.six.year2"
                      />
                    </Form.Label>
                    <FormControl
                      style={{ border: 0, marginLeft: 20, color: "#f5f5f5" }}
                      className="textest"
                      type="text"
                      placeholder={intl.formatMessage({
                        description: "q.ans.enterAmount",
                        id: "q.ans.enterAmount",
                      })}
                      aria-label="Amount (to the nearest dollar)"
                      name="ques6Year2"
                      value={formik.values.ques6Year2}
                      onChange={(e) => {
                        ques6OptionChanges(e);
                        setFormikValue(e);
                      }}
                      onBlur={setSixthQuestionYear2}
                    />
                    <InputGroup.Text style={{ border: 0 }} className="usd">
                      USD
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              {formik.values.ques4 && +remaining !== 0 && (
                <div
                  className="remaining_amount_6th"
                  style={
                    {
                      
                    }
                  }
                  id="Remaining_Amount"
                >
                  <FormattedMessage
                    id="text.remainingamount"
                    defaultMessage="text.remainingamount"
                  ></FormattedMessage>{" "}
                  {remaining}
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          {formik.touched.ques6 && formik.errors.ques6 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques6}
            </div>
          ) : (
            ""
          )}
          {formik.submitCount > 0 &&
          (formik.errors.ques6Year1 || formik.errors.ques6Year2) ? (
            <div className="validation-errorIn ">
              {formik.errors.ques6Year1 || formik.errors.ques6Year2}
            </div>
          ) : (
            ""
          )}
          {nonSchemaErrors.withdrawalGreaterThanImmediateError ? (
            <div className="validation-error ">
              <FormattedMessage
                id="error.withdrawlamount"
                defaultMessage="error.withdrawlamount"
              ></FormattedMessage>
            </div>
          ) : (
            ""
          )}

          {nonSchemaErrors.investAmtNotSufficient6thquestionyear1 ? (
            <div className="validation-error margintopn25">
              <FormattedMessage
                id="error.immediateamount6thyear1"
                defaultMessage="error.immediateamount6thyear1"
              ></FormattedMessage>
            </div>
          ) : (
            ""
          )}

          {nonSchemaErrors.investAmtNotSufficient6thquestionyear2 ? (
            <div className="validation-error">
              <FormattedMessage
                id="error.immediateamount6thyear2"
                defaultMessage="error.immediateamount6thyear2"
              ></FormattedMessage>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Responsive Question 6 End */}

        {/* Responsive Question 7 Start */}
        <div
          className="question-wrapper"
          id={selected.qTwo === "1" ? "disableQuestion" : "question7"}
        >
          <p className="q-text">
            <FormattedMessage id="q.seven" defaultMessage="q.seven" />
          </p>
          <p>
            <span className="smallcontent">
              <FormattedMessage
                id="q.seven.subtext"
                defaultMessage="q.seven.subtext"
              />
            </span>
          </p>
          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  name="ques7"
                  defaultChecked={formik.values.ques7 === "yes"}
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qSeven: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  name="ques7"
                  defaultChecked={formik.values.ques7 === "no"}
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qSeven: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>
          {selected.qSeven === "1" && selected.qTwo !== "1" ? (
            <div>
              <Row style={{ marginBottom: "1.5rem" }}>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  xs={"auto"}
                  md={"auto"}
                >
                  <InputGroup className="question7input">
                    <Form.Label style={{ paddingTop: 5 }}>
                      <FormattedMessage
                        id="q.seven.annually"
                        defaultMessage="q.seven.annually"
                      />
                    </Form.Label>
                    <div>
                      <FormControl
                        style={{ color: "#rgb(245, 245, 245)" }}
                        className="textest q7-value"
                        type="text"
                        name="ques7Amt"
                        value={formik.values.ques7Amt}
                        onChange={setFormikValue}
                        placeholder={intl.formatMessage({
                          description: "q.ans.enterAmount",
                          id: "q.ans.enterAmount",
                        })}
                        aria-label="Amount (to the nearest dollar)"
                      />
                      <InputGroup.Text style={{ border: 0 }} className="usd">
                        USD
                      </InputGroup.Text>
                    </div>
                  </InputGroup>
                </Col>
                <div
                  style={{ textAlign: "center", width: "805px" }}
                  id="immediately_funded"
                >
                  <FormattedMessage
                    id="q.ans.errormessage7th"
                    defaultMessage="q.ans.errormessage7th"
                    values={{ percentagefund: percentFunded }}
                  />
                  {/* {`(${percentFunded} % of the immediately funded amount)`} */}
                </div>
              </Row>
            </div>
          ) : (
            ""
          )}
          {formik.touched.ques7 && formik.errors.ques7 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques7}
            </div>
          ) : (
            ""
          )}
          {formik.submitCount > 0 && formik.errors.ques7Amt ? (
            <div className="validation-error">{formik.errors.ques7Amt}</div>
          ) : (
            ""
          )}

          {nonSchemaErrors.maxAmtPercentToInvestment ? (
            <div className="validation-error">
              <FormattedMessage
                id="error.maximumpercent"
                defaultMessage="error.maximumpercent"
              ></FormattedMessage>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Responsive Question 7 End */}
        <div className="buttonContainer">
          <Row>
            <Col xs={12} md={6}>
              <button className="next-btn btn-block" type="submit">
                <FormattedMessage
                  id="btn.pfSummary"
                  defaultMessage="btn.pfSummary"
                />{" "}
                <span className="right-arrow">
                  <LeftArrow
                    rotate={180}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
                <span className="left-arrow">
                  <LeftArrow
                    rotate={0}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
              </button>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
})
export default MyPortfolio
