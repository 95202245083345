import React from "react";
import { Spinner } from "react-bootstrap";
import "./AppLoader.css";
export default function AppLoader() {
  return (
    <>
      <section className="app-loader">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </section>
      <div className="overlay"></div>
    </>
  );
}
