import React, { useState, useMemo, useContext, useRef, createRef } from "react";
import "./summery.css";
import _ from "lodash";
import {
  convertToNumber,
  riskMapping1,
  riskMapping2,
  responsivnessMapping,
  riskMapping3,
} from "./helper.js";
import { Row, Col } from "react-bootstrap";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import { LeftArrow } from "../../../icons";
import Cross from "../../../icons/cross.svg";
import Tick from "../../../icons/tick.jpeg";
import preparePayLoad from "./preparePayLoad";
import api from "../../../api";
import history from "../../../history";
import Modal from "./modal.js";
import { Context } from "../../Wrapper/Wrapper";
import exportAsImage from "../../../utils/exportAsImage";
import EmailContent from "../EmailContent";
import { ReactDOM } from "react";

const portfolioarr1 = [
  1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11,
];
const portfolioarr2 = [
  1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5,
];
const portfolioarr3 = [
  1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4,
];
const getMemoizedValues = (formikValues) => {
  if (!formikValues) return;
  const invBeh =
    formikValues["Investment Behaviour"] &&
    formikValues["Investment Behaviour"].ques11
      ? formikValues["Investment Behaviour"]
      : JSON.parse(sessionStorage.getItem("InvestmentBehaviour"));
  let riskPlacementposition;
  let portfolioMaxValue;
  let riskPlacementValue;
  let risk_tolerance_category;
  let riskPlacementPercentage;
  let risk_tolerance;

  const riskQuestionSum = _.sum([
    parseInt(invBeh?.ques11),
    // parseInt(invBeh?.ques12),
    parseInt(invBeh?.ques13),
    // parseInt(invBeh?.ques14),
    parseInt(invBeh?.ques15),
    parseInt(invBeh?.ques16),
    // parseInt(invBeh?.ques17),
    parseInt(invBeh?.ques18),
  ]);

  const ResponsivenessQuestionSum = _.sum([
    parseInt(invBeh?.ques12),
    parseInt(invBeh?.ques17),
    parseInt(invBeh?.ques14),
    parseInt(invBeh?.ques19),
  ]);

  const myPortfolio = formikValues["My Portfolio"];
  const range = myPortfolio.ques4;

  const PortfolioPreferences = formikValues["Portfolio Preferences"];
  const {
    ques10checkbox1,
    ques10checkbox2,
    ques10checkbox3,
    ques10checkbox4,
    ques10checkbox5,
    ques10checkbox6,
  } = PortfolioPreferences;
  const showTenthQuestion = convertToNumber(myPortfolio.ques4) >= 10000000;

  const percent =
    (convertToNumber(myPortfolio.ques7Amt) * 100) /
    convertToNumber(myPortfolio.ques4);
  const percentFunded = isNaN(percent) ? "0" : percent.toFixed(2);
  let riskMapping =
    convertToNumber(range) >= 1000000
      ? riskMapping1
      : convertToNumber(range) >= 500000 && convertToNumber(range) <= 1000000
      ? riskMapping2
      : convertToNumber(range) >= 300000 && convertToNumber(range) <= 500000
      ? riskMapping3
      : [];

  const portfolioarr =
    convertToNumber(range) >= 1000000
      ? portfolioarr1
      : convertToNumber(range) >= 500000 && convertToNumber(range) <= 1000000
      ? portfolioarr2
      : convertToNumber(range) >= 300000 && convertToNumber(range) <= 500000
      ? portfolioarr3
      : null;

  for (let element of riskMapping) {
    if (element.sumOfQuestion === riskQuestionSum) {
      riskPlacementposition = element.riskPlacement;
      portfolioMaxValue = _.max(portfolioarr);
      riskPlacementValue = _.divide(riskPlacementposition / portfolioMaxValue);
      riskPlacementPercentage = _.round(_.multiply(riskPlacementValue, 100));
      risk_tolerance_category = element.riskCategory_key;
      risk_tolerance = element.riskPercentage;
    }
  }

  let assessmentWidth;
  let assessmentPosition;
  let riskval;

  if (riskPlacementPercentage >= 0 && riskPlacementPercentage <= 25) {
    assessmentWidth = riskPlacementPercentage;
    assessmentPosition = 1;
  } else if (riskPlacementPercentage > 25 && riskPlacementPercentage <= 50) {
    riskval = ((parseInt(riskPlacementPercentage) - 25) / 25) * 100;
    assessmentWidth = riskval;
    assessmentPosition = 2;
  } else if (riskPlacementPercentage > 50 && riskPlacementPercentage <= 75) {
    riskval = ((parseInt(riskPlacementPercentage) - 50) / 25) * 100;
    assessmentWidth = riskval;
    assessmentPosition = 3;
  } else if (riskPlacementPercentage > 75) {
    riskval = ((parseInt(riskPlacementPercentage) - 75) / 25) * 100;
    assessmentWidth = riskval;
    assessmentPosition = 4;
  }

  let responsiviness;
  let respPercentage;
  let responsiveness_category;
  let respval;

  for (let responsivinessvalue of responsivnessMapping) {
    if (responsivinessvalue.sumOfQuestion === ResponsivenessQuestionSum) {
      responsiviness = responsivinessvalue.responsivenessCategory;
      responsiveness_category = responsivinessvalue.responsiveness_category_key;
      respPercentage = responsivinessvalue.respPercentage;
    }
  }

  let responsivenessWidth;
  let responsivenessPosition;
  if (respPercentage >= 0 && respPercentage <= 33) {
    responsivenessWidth = respPercentage;
    responsivenessPosition = 1;
  } else if (respPercentage > 33 && respPercentage <= 66) {
    respval = ((parseInt(respPercentage) - 33) / 33) * 100;
    responsivenessWidth = respval;
    responsivenessPosition = 2;
  } else if (respPercentage > 66) {
    _.round(_.multiply(riskPlacementValue, 100));
    let respvalDivide = _.round(parseInt(respPercentage) - 66) / 33;
    let respval = _.multiply(respvalDivide, 100);
    responsivenessWidth = respval;
    responsivenessPosition = 3;
  }

  const point1 =
    convertToNumber(myPortfolio.ques4) +
    convertToNumber(myPortfolio.ques5Year1) +
    convertToNumber(myPortfolio.ques5Year2);
  const nowPercentage =
    (convertToNumber(myPortfolio.ques4) /
      (convertToNumber(myPortfolio.ques4) +
        convertToNumber(myPortfolio.ques5Year1) +
        convertToNumber(myPortfolio.ques5Year2))) *
    100;
  const year1Percentage =
    (convertToNumber(myPortfolio.ques5Year1) /
      (convertToNumber(myPortfolio.ques4) +
        convertToNumber(myPortfolio.ques5Year1) +
        convertToNumber(myPortfolio.ques5Year2))) *
    100;
  const year2Percentage =
    (convertToNumber(myPortfolio.ques5Year2) /
      (convertToNumber(myPortfolio.ques4) +
        convertToNumber(myPortfolio.ques5Year1) +
        convertToNumber(myPortfolio.ques5Year2))) *
    100;
  const nowPercentageClient =
    (convertToNumber(myPortfolio.ques5immediate) /
      (convertToNumber(myPortfolio.ques5immediate) +
        convertToNumber(myPortfolio.ques5yr1) +
        convertToNumber(myPortfolio.ques5yr2))) *
    100;
  const year1PercentageClient =
    (convertToNumber(myPortfolio.ques5yr1) /
      (convertToNumber(myPortfolio.ques5immediate) +
        convertToNumber(myPortfolio.ques5yr1) +
        convertToNumber(myPortfolio.ques5yr2))) *
    100;
  const year2PercentageClient =
    (convertToNumber(myPortfolio.ques5yr2) /
      (convertToNumber(myPortfolio.ques5immediate) +
        convertToNumber(myPortfolio.ques5yr1) +
        convertToNumber(myPortfolio.ques5yr2))) *
    100;
  const totalval =
    convertToNumber(myPortfolio.ques6Year1) +
    convertToNumber(myPortfolio.ques6Year2);

  return {
    PortfolioPreferences,
    riskQuestionSum,
    ResponsivenessQuestionSum,
    range,
    ques10checkbox1,
    ques10checkbox2,
    ques10checkbox3,
    ques10checkbox4,
    ques10checkbox5,
    ques10checkbox6,
    showTenthQuestion,
    percent,
    percentFunded,
    riskMapping,
    portfolioarr,
    riskPlacementposition,
    portfolioMaxValue,
    riskPlacementValue,
    risk_tolerance_category,
    riskPlacementPercentage,
    risk_tolerance,
    assessmentWidth,
    assessmentPosition,
    riskval,
    responsiviness,
    respPercentage,
    responsiveness_category,
    respval,
    responsivenessWidth,
    responsivenessPosition,
    point1,
    nowPercentage,
    year1Percentage,
    year2Percentage,
    nowPercentageClient,
    year1PercentageClient,
    year2PercentageClient,
    totalval,
    myPortfolio,
    invBeh,
    showEmailcontent: true,
  };
};

export default function Summery({
  modalOpen,
  closeModal,
  values,
  getFormValues,
  setFormValues,
  formikValues,
  isClient,
  surveyId,
  rmEmail,
  setEmailShow,
  emailshow,
}) {
  const [show, setShow] = useState(false);
  const context = useContext(Context);
  let emailRef = useRef();
  let email = sessionStorage.getItem("otpUserEmail");
  let RMEmail = sessionStorage.getItem("RMEmail");
  const {
    myPortfolio,
    PortfolioPreferences,
    ques10checkbox1,
    ques10checkbox2,
    ques10checkbox3,
    ques10checkbox4,
    ques10checkbox5,
    ques10checkbox6,
    showTenthQuestion,
    percentFunded,
    risk_tolerance_category,
    risk_tolerance,
    assessmentWidth,
    assessmentPosition,
    responsiveness_category,
    responsivenessWidth,
    responsivenessPosition,
    point1,
    nowPercentage,
    year1Percentage,
    year2Percentage,
    nowPercentageClient,
    year1PercentageClient,
    year2PercentageClient,
    totalval,
    showEmailcontent,
  } = useMemo(() => getMemoizedValues(formikValues), [formikValues]);

  // handle submit and call API

  const submitFormValues = async (e) => {
    var imagetag = document.getElementById("mail")?.innerHTML;
    const payload = preparePayLoad(formikValues, surveyId, isClient);
    try {
      // const token = await getAccessTokenSilently();
      const resp = await api.post(
        process.env.REACT_APP_SAVE_RESPONSE_ENDPOINT,
        payload,
        {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        }
      );

      // console.log("resp from API", resp);
      if (resp.data.message === "success") {
        history.push("/submited", { status: "submitted" });
        const res = await api.post(process.env.REACT_APP_OTP_ENDPOINT, {
          operation: "send-email",
          htmlBody: imagetag,
          rmEmail: rmEmail ? rmEmail : RMEmail,
          email: email,
        });
        // console.log("value for res---",res)
        sessionStorage.setItem("otpValidated", "");
        sessionStorage.setItem("otpUserEmail", "");
        sessionStorage.setItem("RMEmail", "");
        sessionStorage.setItem("MyPortfolios", "");
        sessionStorage.setItem("MyPortfolioPreferences", "");
        sessionStorage.setItem("InvestmentBehaviour", "");
        sessionStorage.setItem("Suitability", "");
        setShow(false);
        // console.log(setShow, "setShow");
      }
    } catch (e) {
      // console.log("error from API", e);
    }
  };

  let point = 0;
  const exportRef = useRef();
  const modalContent = () => {
    return (
      <>
        {show && <Modal show={setShow} onsubmit={submitFormValues} />}
        <div ref={exportRef}>
          <div className="summeryContainer" id="summary">
            <h3 className="summery-heading">
              <FormattedMessage
                id="header.smmarytext"
                defaultMessage="header.smmarytext"
              />
            </h3>
            {!isClient && (
              <Row className="rowsummary">
                <Col xs={1} sm={1}>
                  <div className="point">
                    <label>{++point}</label>
                  </div>
                </Col>
                <Col xs={11} sm={11}>
                  <div className="summery-point">
                    <FormattedMessage
                      id="firstpara.textpart1"
                      defaultMessage="firstpara.textpart1"
                    />
                    <span className="summerySpanHighlight">
                      {isClient ? (
                        <FormattedMessage
                          id="firstpara.textadd"
                          defaultMessage="firstpara.textadd"
                        />
                      ) : (
                        <FormattedMessage
                          id="firstpara.textinvest"
                          defaultMessage="firstpara.texinvest"
                        />
                      )}
                    </span>
                    <span className="leftpadding"></span>
                    {isClient ? (
                      <FormattedMessage
                        id="firstpara.textpartclient2"
                        defaultMessage="firstpara.textpartclient2"
                      />
                    ) : (
                      <FormattedMessage
                        id="firstpara.textpart2"
                        defaultMessage="firstpara.textpart2"
                      />
                    )}
                    &nbsp;
                    {myPortfolio.ques5 === "yes" && myPortfolio.ques4 ? (
                      <span className="summerySpanHighlight1 numberalignment">
                        {"$" + point1.toLocaleString()}
                      </span>
                    ) : (
                      <span className="summerySpanHighlight1 numberalignment">
                        {"$" + point1.toLocaleString()}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {isClient && myPortfolio.ques4 && (
              <Row className="rowsummary">
                <Col xs={1} sm={1}>
                  <div className="point">
                    <label>{++point}</label>
                  </div>
                </Col>
                <Col xs={11} sm={11}>
                  <div className="summery-point">
                    <FormattedMessage
                      id="firstpara.textpart1"
                      defaultMessage="firstpara.textpart1"
                    />

                    <span className="summerySpanHighlight">
                      {isClient ? (
                        <FormattedMessage
                          id="firstpara.textadd"
                          defaultMessage="firstpara.textadd"
                        />
                      ) : (
                        <FormattedMessage
                          id="firstpara.textinvest"
                          defaultMessage="firstpara.texinvest"
                        />
                      )}
                    </span>
                    <span className="leftpadding"></span>
                    {isClient ? (
                      <FormattedMessage
                        id="firstpara.textpartclient2"
                        defaultMessage="firstpara.textpartclient2"
                      />
                    ) : (
                      <FormattedMessage
                        id="firstpara.textpart2"
                        defaultMessage="firstpara.textpart2"
                      />
                    )}

                    {myPortfolio.ques5 === "yes" && myPortfolio.ques4 ? (
                      <span className="summerySpanHighlight1 numberalignment">
                        {"$" + point1.toLocaleString()}
                      </span>
                    ) : (
                      <span className="summerySpanHighlight1 numberalignment">
                        {"$" + point1.toLocaleString()}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {!isClient && (
              <Row className="rowsummary">
                <Col xs={1}>
                  <div className="point">
                    <label>{++point}</label>
                  </div>
                </Col>
                <Col xs={11}>
                  <p className="summery-point">
                    <FormattedMessage
                      id="secondpara.textpart1"
                      defaultMessage="secondpara.textpart1"
                    />
                  </p>
                </Col>
              </Row>
            )}
            {isClient && myPortfolio.ques4 && (
              <Row className="rowsummary">
                <Col xs={1}>
                  <div className="point">
                    <label>{++point}</label>
                  </div>
                </Col>
                <Col xs={11}>
                  <p className="summery-point">
                    <FormattedMessage
                      id="secondpara.textpart1"
                      defaultMessage="secondpara.textpart1"
                    />
                  </p>
                </Col>
              </Row>
            )}
            {isClient && myPortfolio.ques4 && (
              <div className="question2grid">
                {myPortfolio.ques5immediate !== 0 &&
                  myPortfolio.ques5immediate && (
                    <div className="rowsummary">
                      <div className="nowContainer">
                        <div className="nowHeader">
                          <div>
                            <FormattedMessage
                              id="secondpara.textpartheader"
                              defaultMessage="secondpara.textpartheader"
                            />
                          </div>
                        </div>
                        <p>${myPortfolio.ques5immediate}</p>
                        <p>
                          {Number.parseFloat(nowPercentageClient).toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  )}
                {myPortfolio.ques5yr1 !== "" && myPortfolio.ques5yr1 !== 0 && (
                  <>
                    <div className="rowsummary">
                      <div className="nowContainer">
                        <div className="nowHeader">
                          <FormattedMessage
                            id="secondtextbox.header"
                            defaultMessage="secondtextbox.header"
                          />
                        </div>
                        <p>${myPortfolio.ques5yr1}</p>
                        <p>
                          {Number.parseFloat(year1PercentageClient).toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {myPortfolio.ques5yr2 !== "" && (
                  <>
                    <div className="rowsummary">
                      <div className="nowContainer">
                        <div className="nowHeader">
                          <FormattedMessage
                            id="thirdtextbox.header"
                            defaultMessage="thirdtextbox.header"
                          />
                        </div>
                        <p>${myPortfolio.ques5yr2}</p>
                        <p>
                          {Number.parseFloat(year2PercentageClient).toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {!isClient && (
              <div className="question2grid">
                <div className="rowsummary">
                  <div className="nowContainer">
                    <div className="nowHeader">
                      <div>
                        <FormattedMessage
                          id="secondpara.textpartheader"
                          defaultMessage="secondpara.textpartheader"
                        />
                      </div>
                    </div>
                    <p>${myPortfolio.ques4}</p>
                    <p>{Number.parseFloat(nowPercentage).toFixed(2)}%</p>
                  </div>
                </div>
                {((myPortfolio.ques5 === "yes" &&
                  myPortfolio.ques5Year1 &&
                  myPortfolio.ques5Year1 !== undefined) ||
                  "" ||
                  null) && (
                  <>
                    <div className="rowsummary">
                      <div className="nowContainer">
                        <div className="nowHeader">
                          <FormattedMessage
                            id="secondtextbox.header"
                            defaultMessage="secondtextbox.header"
                          />
                        </div>
                        <p>${myPortfolio.ques5Year1}</p>
                        <p>{Number.parseFloat(year1Percentage).toFixed(2)}%</p>
                      </div>
                    </div>
                  </>
                )}
                {myPortfolio.ques5 === "yes" && myPortfolio.ques5Year2 !== "" && (
                  <>
                    <div className="rowsummary">
                      <div className="nowContainer">
                        <div className="nowHeader">
                          <FormattedMessage
                            id="thirdtextbox.header"
                            defaultMessage="thirdtextbox.header"
                          />
                        </div>
                        <p>${myPortfolio.ques5Year2}</p>
                        <p>{Number.parseFloat(year2Percentage).toFixed(2)}%</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {!isClient && (
              <Row className="rowsummary">
                <Col xs={12}>
                  <p className="assetAllocation">
                    <FormattedMessage
                      id="secondpara.textpart2"
                      defaultMessage="secondpara.textpart2"
                    />
                    &nbsp;
                    <span className="summerySpanHighlight numberalignment">
                      <span>$</span>
                      {isClient
                        ? myPortfolio.ques5immediate
                        : myPortfolio.ques4}
                    </span>
                  </p>
                </Col>
              </Row>
            )}
            {isClient && myPortfolio.ques4 && (
              <Row className="rowsummary">
                <Col xs={12}>
                  <p className="assetAllocation">
                    <FormattedMessage
                      id="secondpara.textpart2"
                      defaultMessage="secondpara.textpart2"
                    />
                    <span className="summerySpanHighlight numberalignment">
                      <span>$</span>
                      {isClient
                        ? myPortfolio.ques5immediate
                        : myPortfolio.ques4}
                    </span>
                  </p>
                </Col>
              </Row>
            )}
            <Row className="rowsummary">
              <Col xs={1}>
                <div className="point">
                  <label>{++point}</label>
                </div>
              </Col>
              {myPortfolio.ques7 === "yes" ? (
                <Col xs={11}>
                  <p className="summery-point">
                    <FormattedMessage
                      id="thridpara.text"
                      defaultMessage="thridpara.text"
                    />{" "}
                    <span className="summerySpanHighlight numberalignment">
                      ${myPortfolio.ques7Amt}
                    </span>
                    <span className="secondtext">
                      {" "}
                      <FormattedMessage
                        id="q.ans.errormessage7thsummary"
                        defaultMessage="q.ans.errormessage7thsummary"
                        values={{
                          summarypercentage: percentFunded,
                        }}
                      />
                    </span>
                  </p>
                </Col>
              ) : (
                <Col xs={11}>
                  <p className="summery-point">
                    <FormattedMessage
                      id="thridpara.text"
                      defaultMessage="thridpara.text"
                    />{" "}
                    <span className="summerySpanHighlight">
                      <FormattedMessage
                        id="thridpara.no"
                        defaultMessage="thridpara.no"
                      />
                    </span>
                  </p>
                </Col>
              )}
            </Row>
            <Row className="rowsummary">
              <Col xs={1}>
                <div className="point">
                  <label>{++point}</label>
                </div>
              </Col>
              <Col xs={11}>
                <p className="summery-point">
                  <FormattedMessage
                    id="fourthpara.text"
                    defaultMessage="fourthpara.text"
                  />
                  &nbsp;
                  <span className="summerySpanHighlight">
                    {myPortfolio.ques3 && myPortfolio.ques3 != "" && (
                      <FormattedMessage
                        id={myPortfolio.ques3}
                        defaultMessage=""
                      />
                    )}
                  </span>
                </p>
              </Col>
            </Row>
            <Row className="rowsummary">
              <Col xs={1}>
                <div className="point">
                  <label>{++point}</label>
                </div>
              </Col>
              <Col xs={11}>
                <p className="summery-point">
                  <FormattedMessage
                    id="fifthpara.textpart1"
                    defaultMessage="fifthpara.textpart1"
                  />
                  <span className="leftpadding"></span>
                  {PortfolioPreferences.ques9 === true ? (
                    <span className="summerySpanHighlight">
                      <FormattedMessage
                        id="fifthpara.shariah"
                        defaultMessage="fifthpara.shariah"
                      />
                    </span>
                  ) : (
                    <span className="summerySpanHighlight">
                      <FormattedMessage
                        id="fifthpara.conventional"
                        defaultMessage="fifthpara.conventional"
                      />
                    </span>
                  )}
                  <span className="leftpadding">
                    &nbsp;
                    <FormattedMessage
                      id="fifthpara.portfolio"
                      defaultMessage="fifthpara.portfolio"
                    />
                  </span>
                </p>
              </Col>
            </Row>
            {myPortfolio.ques6 === "yes" && (
              <>
                <Row className="rowsummary">
                  <Col xs={1}>
                    <div className="point">
                      <label>{++point}</label>
                    </div>
                  </Col>
                  <Col xs={11}>
                    <p className="summery-point">
                      <FormattedMessage
                        id="sixthpara.first"
                        defaultMessage="sixthpara.first"
                      />
                      <span className="summerySpanHighlight">
                        &nbsp;
                        <FormattedMessage
                          id="sixthpara.second"
                          defaultMessage="sixthpara.second"
                        />
                        {myPortfolio.ques6Year2 && " & 2"}
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row className="question6grid">
                  {myPortfolio.ques6Year1 && (
                    <Col className="rowsummary" md={3} lg={3} xs={5}>
                      <div className="nowContainer">
                        <div className="withinyear">
                          <p>
                            <FormattedMessage
                              id="sixthbox.first"
                              defaultMessage="sixthbox.first"
                            />
                          </p>
                        </div>
                        <p>${myPortfolio.ques6Year1}</p>
                      </div>
                    </Col>
                  )}

                  {myPortfolio.ques6Year2 && (
                    <Col className="rowsummary" md={3} lg={3} xs={5}>
                      <div className="nowContainer">
                        <div className="withinyear">
                          <p>
                            <FormattedMessage
                              id="sixthbox.second"
                              defaultMessage="sixthbox.second"
                            />
                          </p>
                        </div>
                        <p>${myPortfolio.ques6Year2}</p>
                      </div>
                    </Col>
                  )}
                  <Col className="rowsummary" md={3} lg={3}>
                    <div className="nowContainer" style={{ border: "none" }}>
                      <div className="withinyear ">
                        <p>
                          <FormattedMessage
                            id="sixthbox.total"
                            defaultMessage="sixthbox.total"
                          />
                        </p>
                      </div>
                      <p>${totalval.toLocaleString()}</p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {!isClient && showTenthQuestion && (
              <Row className="rowsummary">
                <Col className="point" xs={1} md={1} lg={1}>
                  {myPortfolio.ques6 === "no" ? (
                    <label>{++point}</label>
                  ) : (
                    <label>7</label>
                  )}
                </Col>
                <Col>
                  <p className="summery-point">
                    <FormattedMessage
                      id="sixthpara.textpart1"
                      defaultMessage="sixthpara.textpart1"
                    />
                    <span className="leftpadding"></span>
                    <span className="summerySpanHighlight">
                      <FormattedMessage
                        id="sixthpara.textpart2"
                        defaultMessage="sixthpara.textpart2"
                      />
                    </span>
                  </p>
                </Col>
              </Row>
            )}
            {isClient ? (
              <div></div>
            ) : (
              <div>
                {showTenthQuestion && (
                  <div className="capitalgrid">
                    <div className="capital">
                      <FormattedMessage
                        id="seventh.header"
                        defaultMessage="seventh.header"
                      />
                    </div>
                    <div
                      className="status"
                      id={!ques10checkbox1 && "inactiveStatus"}
                    >
                      <FormattedMessage
                        id="seventh.firstcheckboxtop"
                        defaultMessage="seventh.firstcheckboxtop"
                      />
                      <br />
                      <span className="value">
                        <FormattedMessage
                          id="seventh.firstcheckboxtext"
                          defaultMessage="seventh.firstcheckboxtext"
                        />
                      </span>
                      {ques10checkbox1 ? (
                        <div className="tick">
                          <img src={Tick} alt="tick" />
                        </div>
                      ) : (
                        <div className="cross">
                          <img src={Cross} alt="cross" />
                        </div>
                      )}
                    </div>
                    <div
                      className="status"
                      id={!ques10checkbox2 && "inactiveStatus"}
                    >
                      <FormattedMessage
                        id="seventh.secondcheckboxtop"
                        defaultMessage="seventh.secondcheckboxtop"
                      />
                      <br />
                      <span className="value">
                        <FormattedMessage
                          id="seventh.firstcheckboxtext"
                          defaultMessage="seventh.firstcheckboxtext"
                        />
                      </span>
                      {ques10checkbox2 ? (
                        <div className="tick">
                          <img src={Tick} alt="tick" />
                        </div>
                      ) : (
                        <div className="cross">
                          <img src={Cross} alt="cross" />
                        </div>
                      )}
                    </div>

                    <div
                      className="status"
                      id={!ques10checkbox3 && "inactiveStatus"}
                    >
                      <FormattedMessage
                        id="seventh.secondcheckboxtop"
                        defaultMessage="seventh.secondcheckboxtop"
                      />
                      <br />
                      <span className="value">
                        <FormattedMessage
                          id="seventh.thirdcheckboxtext"
                          defaultMessage="seventh.thirdcheckboxtext"
                        />
                      </span>
                      {ques10checkbox3 ? (
                        <div className="tick">
                          <img src={Tick} alt="tick" />
                        </div>
                      ) : (
                        <div className="cross">
                          <img src={Cross} alt="cross" />
                        </div>
                      )}
                    </div>

                    <div className="capital">
                      <FormattedMessage
                        id="seventh.header2"
                        defaultMessage="seventh.header2"
                      />
                    </div>
                    <div
                      className="status"
                      id={!ques10checkbox4 && "inactiveStatus"}
                    >
                      <FormattedMessage
                        id="seventh.firstcheckboxtop"
                        defaultMessage="seventh.firstcheckboxtop"
                      />
                      <br />
                      <span className="value">
                        <FormattedMessage
                          id="seventh.Realestatedebt"
                          defaultMessage="seventh.Realestatedebt"
                        />
                      </span>
                      {ques10checkbox4 ? (
                        <div className="tick">
                          <img src={Tick} alt="tick" />
                        </div>
                      ) : (
                        <div className="cross">
                          <img src={Cross} alt="cross" />
                        </div>
                      )}
                    </div>
                    <div
                      className="status"
                      id={!ques10checkbox5 && "inactiveStatus"}
                    >
                      <FormattedMessage
                        id="seventh.firstcheckboxtop"
                        defaultMessage="seventh.firstcheckboxtop"
                      />{" "}
                      <br />
                      <span className="value">
                        <FormattedMessage
                          id="seventh.fifthcheckboxtext"
                          defaultMessage="seventh.fifthcheckboxtext"
                        />
                      </span>
                      {ques10checkbox5 ? (
                        <div className="tick">
                          <img src={Tick} alt="tick" />
                        </div>
                      ) : (
                        <div className="cross">
                          <img src={Cross} alt="cross" />
                        </div>
                      )}
                    </div>
                    <div
                      className="status"
                      id={!ques10checkbox6 && "inactiveStatus"}
                    >
                      <FormattedMessage
                        id="seventh.firstcheckboxtop"
                        defaultMessage="seventh.firstcheckboxtop"
                      />
                      <br />
                      <span className="value">
                        {" "}
                        <FormattedMessage
                          id="seventh.thirdcheckboxtext"
                          defaultMessage="seventh.thirdcheckboxtext"
                        />
                      </span>
                      {ques10checkbox6 ? (
                        <div className="tick">
                          <img src={Tick} alt="tick" />
                        </div>
                      ) : (
                        <div className="cross">
                          <img src={Cross} alt="cross" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <h3 className="riskScore">
              <FormattedMessage
                id="header.summarytext2"
                defaultMessage="header.summarytext2"
              />
            </h3>
            <div className="riskprofileheader">
              <FormattedMessage
                id="subheader.summarytext1"
                defaultMessage="subheader.summarytext1"
              />
            </div>
            <div className="cnt">
              <div className="progress1 progress_bar_start">
                <div
                  className="progress1-bar progress_bar_start"
                  style={
                    assessmentPosition === 1
                      ? { width: `${assessmentWidth}%` }
                      : { width: "100%" }
                  }
                ></div>
                {assessmentPosition === 1 ? (
                  <div className="pointr">
                    <div className="labelp">{risk_tolerance}</div>
                  </div>
                ) : null}
              </div>

              <div className="progress1">
                <div
                  className="progress1-bar"
                  style={(() => {
                    switch (assessmentPosition) {
                      case 3:
                      case 4:
                        return { width: "100%" };
                      case 2:
                        return { width: `${assessmentWidth}%` };
                      default:
                        return;
                    }
                  })()}
                ></div>
                {assessmentPosition === 2 ? (
                  <div className="pointr">
                    <div className="labelp">{risk_tolerance}</div>
                  </div>
                ) : null}
              </div>

              <div className="progress1">
                <div
                  className="progress1-bar"
                  style={(() => {
                    switch (assessmentPosition) {
                      case 4:
                        return { width: "100%" };
                      case 3:
                        return { width: `${assessmentWidth}%` };
                      default:
                        return;
                    }
                  })()}
                ></div>
                {assessmentPosition === 3 ? (
                  <div className="pointr">
                    <div className="labelp">{risk_tolerance}</div>
                  </div>
                ) : null}
              </div>

              <div className="progress1 progress_bar_end">
                <div
                  className="progress1-bar"
                  style={(() => {
                    switch (assessmentPosition) {
                      case 4:
                        return { width: `${assessmentWidth}%` };
                      default:
                        return;
                    }
                  })()}
                ></div>
                {assessmentPosition === 4 ? (
                  <div className="pointr">
                    <div className="labelp">{risk_tolerance}</div>
                  </div>
                ) : null}
              </div>
            </div>

            <Row>
              <Col size-lg="5" size-md="6" size-sm="6" size-xs="6">
                <div style={{ fontSize: "12px" }}>
                  <b>
                    <FormattedMessage
                      id="summary.conservative"
                      defaultMessage="summary.conservative"
                    />{" "}
                  </b>
                </div>
              </Col>
              <Col
                size-lg="5"
                size-md="6"
                size-sm="6"
                size-xs="6"
                style={{ textAlign: "right" }}
              >
                <div className="textalignment" style={{ fontSize: "12px" }}>
                  <b>
                    <FormattedMessage
                      id="summary.aggressive"
                      defaultMessage="summary.aggressive"
                    />
                  </b>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "15px" }}>
              <Col size-lg="10" size-md="6" size-sm="12" size-xs="12">
                <div style={{ fontWeight: 200, fontSize: 22 }}>
                  {risk_tolerance_category && risk_tolerance_category != "" && (
                    <FormattedMessage
                      id={`${risk_tolerance_category}`}
                      defaultMessage={`${risk_tolerance_category}`}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col size-lg="10" size-md="6" size-sm="12" size-xs="12">
                {risk_tolerance_category === "Conservative_Header" ? (
                  <div style={{ fontWeight: 200, fontSize: 14 }}>
                    <FormattedMessage
                      id="summary.conservative.text"
                      defaultMessage="summary.conservative.text"
                    />
                  </div>
                ) : risk_tolerance_category ===
                  "Moderately_Conservative_Header" ? (
                  <div style={{ fontWeight: 200, fontSize: 14 }}>
                    <FormattedMessage
                      id="summary.moderatelyconservative.text"
                      defaultMessage="summary.moderatelyconservative.text"
                    />
                  </div>
                ) : risk_tolerance_category ===
                  "Moderately_Aggressive_Header" ? (
                  <div style={{ fontWeight: 200, fontSize: 14 }}>
                    <FormattedMessage
                      id="summary.moderatelyaggressive.text"
                      defaultMessage="summary.moderatelyaggressive.text"
                    />
                  </div>
                ) : risk_tolerance_category === "Aggressive_Header" ? (
                  <div style={{ fontWeight: 200, fontSize: 14 }}>
                    <FormattedMessage
                      id="summary.aggressive.text"
                      defaultMessage="summary.aggressive.text"
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
            <div className="riskprofileheader margintop30">
              <FormattedMessage
                id="subheader.summarytext2"
                defaultMessage="subheader.summarytext2"
              />{" "}
            </div>
            <div className="cnt">
              <div className="progress1 progress_bar_start">
                <div
                  className="progress1-bar progress_bar_start"
                  style={
                    responsivenessPosition === 1
                      ? { width: `${responsivenessWidth}%` }
                      : { width: "100%" }
                  }
                ></div>
                {responsivenessPosition === 1 ? (
                  <div className="pointr"></div>
                ) : null}
              </div>
              <div className="progress1">
                <div
                  className="progress1-bar "
                  style={(() => {
                    switch (responsivenessPosition) {
                      case 3:
                        return { width: "100%" };
                      case 2:
                        return { width: `${responsivenessWidth}%` };
                      default:
                        return;
                    }
                  })()}
                ></div>
                {responsivenessPosition === 2 ? (
                  <div className="pointr"></div>
                ) : null}
              </div>
              <div className="progress1 progress_bar_end">
                <div
                  className="progress1-bar"
                  style={(() => {
                    switch (responsivenessPosition) {
                      case 3:
                        return { width: `${responsivenessWidth}%` };
                      default:
                        return;
                    }
                  })()}
                ></div>
                {responsivenessPosition === 3 ? (
                  <div className="pointr"></div>
                ) : null}
              </div>
            </div>
            <Row>
              <Col size-lg="5" size-md="6" size-sm="6" size-xs="6">
                <div style={{ fontSize: "12px" }}>
                  <b>
                    <FormattedMessage
                      id="summary.calculated"
                      defaultMessage="summary.calculated"
                    />
                  </b>
                </div>
              </Col>
              <Col
                size-lg="5"
                size-md="6"
                size-sm="6"
                size-xs="6"
                style={{ textAlign: "right" }}
              >
                <div className="textalignment" style={{ fontSize: "12px" }}>
                  <b>
                    <FormattedMessage
                      id="summary.spontaneous"
                      defaultMessage="summary.spontaneous"
                    />
                  </b>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "15px" }}>
              <Col size-lg="10" size-md="6" size-sm="12" size-xs="12">
                <div style={{ fontWeight: 200, fontSize: 22 }}>
                  {responsiveness_category && responsiveness_category != "" && (
                    <FormattedMessage
                      id={`${responsiveness_category}`}
                      defaultMessage={`${responsiveness_category}`}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col size-lg="10" size-md="6" size-sm="12" size-xs="12">
                {responsiveness_category === "Cool_Responder_Header" ? (
                  <div style={{ fontWeight: 200, fontSize: 14 }}>
                    <FormattedMessage
                      id="summary.coolResponder"
                      defaultMessage="summary.coolResponder"
                    />
                  </div>
                ) : responsiveness_category === "Paced_Responder_Header" ? (
                  <div style={{ fontWeight: 200, fontSize: 14 }}>
                    <FormattedMessage
                      id="summary.pacedResponder"
                      defaultMessage="summary.pacedResponder"
                    />
                  </div>
                ) : responsiveness_category === "Fast_Responder_Header" ? (
                  <div style={{ fontWeight: 200, fontSize: 14 }}>
                    <FormattedMessage
                      id="summary.fastResponder"
                      defaultMessage="summary.fastResponder"
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: 60 }}>
            <Col xs={5}>
              <button className="back-btn float-left-btn" onClick={closeModal}>
                <span className="right-arrow">
                  <LeftArrow
                    rotate={0}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
                <span className="left-arrow" style={{ marginLeft: 5 }}>
                  <LeftArrow
                    rotate={180}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
                <FormattedMessage id="nav.back" defaultMessage="nav.back" />
              </button>
            </Col>
            <Col xs={7}>
              <button
                className="nextButton float-right-btn"
                onClick={() => {
                  setShow(true);
                }}
              >
                <FormattedMessage id="btn.submit" defaultMessage="btn.submit" />{" "}
                <span className="right-arrow">
                  <LeftArrow
                    rotate={180}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
                <span className="left-arrow" style={{ marginLeft: 5 }}>
                  <LeftArrow
                    rotate={0}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
              </button>
            </Col>
          </Row>
        </div>
        {showEmailcontent && <EmailContent formikValues={formikValues} />}
      </>
    );
  };
  return (
    <ConfirmationModal
      showModal={modalOpen}
      closeModal={closeModal}
      content={modalContent()}
    />
  );
}
