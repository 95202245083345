import React from "react";
import Info from "../../icons/Info.js";
export default function NoMatch() {
  return (
    <div style={{width:"320px", margin:"0 auto", color:"#b99855", height:"100px", top:"30%",left:"0", right:"0", position:"absolute"}}>
      <div className="middle-box shadow" style={{textAlign:"center"}}>
        <span >
        <Info color={"#b99855"} height={100} width={100}/>
        </span>
        <h1 style={{fontSize:"36px"}}>
          <b>Form Not Found!</b>
        </h1>
        <p>
          Please Contact Your<strong> Relationship Manager</strong>
        </p>
      </div>
    </div>
  );
}
