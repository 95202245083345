import React from "react";
import PropTypes from "prop-types";
import { LeftArrow } from "../../../icons";
import { FormattedMessage, useIntl } from "react-intl";

const Modal = (props) => {
  return (
    <div className="confirm-box">

      <p className="header-pop-up">
        <FormattedMessage id="modal.areyousure.text" defaultMessage="modal.areyousure.text" />
      </p>
      <p className="firstline-pop-up">
        <FormattedMessage id="modal.submit.text1" defaultMessage="modal.submit.text1" />
      <span className="modalSpanHighlight">
          <FormattedMessage id="modal.submit.text2" defaultMessage="modal.submit.text2" />
     </span>
     <span className="padding_lasttext">
        <FormattedMessage id="modal.submit.text3" defaultMessage="modal.submit.text3" />
        </span>
      </p>
      <button className="back-btn float-left-btn"
        onClick={()=> {props.show(false)}}
      >
      <span className="right-arrow">
                  <LeftArrow
                    rotate={0}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
                <span className="left-arrow" style={{ marginLeft: 5 }}>
                  <LeftArrow
                    rotate={180}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
        <FormattedMessage id="modal.close.text" defaultMessage="modal.close.text" />
         
      </button>
      <button  className="nextButton float-right-btn"
        onClick={()=> {props.onsubmit()}}
      >
        <FormattedMessage id="modal.yes.submit.text" defaultMessage="modal.yes.submit.text" />
      
      </button>
    </div>
  );
};

export default Modal;
