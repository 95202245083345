import { Modal, Button } from "react-bootstrap";
import "./ConfirmationModal.css";
export default function ConfirmationModal(props) {
  return (
    <Modal
      size='lg'
      show={props.showModal}
      onHide={props.closeCallback}
      aria-labelledby='example-modal-sizes-title-md'
      centered
      className='confirmation-modal'
      backdrop='static'
    >
      <Modal.Body>{props.content}</Modal.Body>
      {props.children}

      {/* <Modal.Body>{props.setBody(props.isInterested)}</Modal.Body> */}
    </Modal>
  );
}
