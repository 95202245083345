import React from "react";

export default function LeftArrow(props) {
  const {width, height, color} = props;
  const rotate = "rotate("+props.rotate+")";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={rotate} transform-origin="center">
      <path
        d="M17.0002 23.4139L6.29325 12.7069C5.90225 12.3159 5.90225 11.6839 6.29325 11.2929L17.0002 0.585938L18.4142 1.99994L8.41425 11.9999L18.4142 21.9999L17.0002 23.4139Z"
        fill={color}
      />
      </g>
    </svg>
  );
}
