import React, { useEffect, useState, useContext, useRef, createContext } from "react";
import "./LandingPage.css";
import Suitability from "./Suitability";
import MyPortfolio from "./MyPortfolio";
import InvestmentBehaviour from "./InvestmentBehaviour";
import Modal from "./summery/modal.js";
import Summery from "./summery/Summery";
import { FormattedMessage } from "react-intl";
import PortfolioPreferences from "./PortfolioPreferences";
import { Context } from "../Wrapper/Wrapper";
import Header from "../../components/Header/Header";
import { useParams } from "react-router-dom";
import Loading from "../../components/AppLoader/AppLoader";
import api from "../../api";
import history from "../../history";
import EmailContent from "./EmailContent";
const TabsArray = [
  "My Portfolio",
  "Portfolio Preferences",
  "Investment Behaviour",
  "Suitability"
]

const LandingPageContext = createContext();

const formValues = {
  "My Portfolio": {},
  "Portfolio Preferences": {
    ques8: false,
    ques9: false,
    ques10checkbox1: true,
    ques10checkbox2: true,
    ques10checkbox3: true,
    ques10checkbox4: true,
    ques10checkbox5: true,
    ques10checkbox6: true,
  },
  "Investment Behaviour": {},
  Suitability: {},
};

const convertToNumber = (value) => {
  let numberValue = 0;
  if (value) {
    if (value === "0") {
      numberValue = 0;
    } else {
      numberValue = value.replace(/[^0-9]/g, "") - 0;
    }
  }
  return numberValue;
};

const LandingPage = (props) => {
  const context = useContext(Context);
  const { code } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [personData, setPersonData] = useState({});
  const [suitabilityValues, setSuitabilityValues] = useState();
  const [suitabilitySubmitBack, setSuitabilitySubmitBack] = useState(false);
  const [show, setShow] = useState(false);
  const [emailshow, setEmailShow] = useState(false);
  const tempValues = {
    ques11: 5,
    ques12: 4,
    ques13: 3,
    ques14: 2,
    ques15: 1,
    ques16: 5,
    ques17: 4,
    ques18: 3,
    ques19: 4,
  };
  const [summeryModal, setSummeryModal] = useState(false);
  const [investment, setInvestment] = useState("");
  const [custname,setCustName] = useState("");
  const [rmEmail,setRMEmail] = useState("");
  const handleInvestAmount = (e) => {
    setInvestment(e.target.value);
  };
  const [activeTab, setActiveTab] = useState("My Portfolio");
  const setPortfolioTouched = () => {
    portfolioref.current && portfolioref.current.touchedMyportfolio();
  };
  const handleActiveTab = (tabName) => {
    const isGoingBack = TabsArray.indexOf(activeTab) > TabsArray.indexOf(tabName) ? true : false;
    if (activeTab === "My Portfolio") {
      const val = portfolioref.current && portfolioref.current.validateMyportfolio();
      if (val && Object.keys(val.errors).length === 0) {
        portfolioref.current && portfolioref.current.submitMyportfolio();
        setActiveTab(tabName);
      }
    } else if (activeTab === "Portfolio Preferences") {
      const val = portfoliopreferenceref.current && portfoliopreferenceref.current.validatePortfoliopreferences();
      if (val && Object.keys(val.errors).length === 0) {
        portfoliopreferenceref.current && portfoliopreferenceref.current.submitPortfoliopreferences();
        setActiveTab(tabName);
      }
    } else if (activeTab === "Investment Behaviour") {
      const val = investmentbehaviourref.current && investmentbehaviourref.current.validateInvestmentBehaviour();
      if(isGoingBack){
        val.errors={}
        setFormValues(val.values)
        setActiveTab(tabName); 
      }
     else if (val && Object.keys(val.errors).length === 0) {
        investmentbehaviourref.current && investmentbehaviourref.current.submitInvestmentBehaviour();
        setActiveTab(tabName); 
      }
    } else if (activeTab === "Suitability") {
      const val = suitabilityref.current && suitabilityref.current.validateSuitability();
      setSuitabilitySubmitBack(true);
      if(isGoingBack){
        val.errors={}
        // console.log({test: val.values})
        setFormValues(val.values)
        setActiveTab(tabName); 
      }
     else if (val && Object.keys(val.errors).length === 0) {
        suitabilityref.current && suitabilityref.current.submitSuitability();
        setActiveTab(tabName);
      }
    } 
  };
  const setFormValues = (formValue) => {
    if (formValue) {
      formValues[activeTab] = formValue;
    }
  };

  const getFormValues = (tabName) => {
    return formValues[tabName];
  };

  useEffect(() => {
    const getInstanceData = async (instanceCode) => {
      // try {
        
        // const token = await getAccessTokenSilently();
        const payload = {
          code: instanceCode,
          email: context.userEmail ? context.userEmail : sessionStorage.getItem("otpUserEmail")
        };

        const resp = await api.post(process.env.REACT_APP_GET_INSTANCE_ENDPOINT, payload, {
         
        });
        // console.log("resp from GetInstance API", resp);
        if (resp.data?.count === 0 && resp.data?.results?.length === 0) {
          history.push("/not-found");
        } else {
          const personId = resp.data?.results[0].PersonId;
          const instanceStage = resp.data?.results[0].instancestage?.InstanceStage;
          const surveyType = resp.data?.results[0].survey?.Survey;

          if (surveyType.toLowerCase() === "client") {
            context.setClient(true);
          } //setting client context
          if (instanceStage.toLowerCase() !== "sent to client") {
            history.push("/submited", { status: "alreadysubmitted" });
          } else {
            const personResp = await api.post(
              process.env.REACT_APP_GET_PERSON_ENDPOINT,
              { personId: personId },
              {
  
              }
            );
            if (personResp.data?.results?.length > 0) {
              // console.log("personResp:",personResp.data?.results[0]);
              const custpersondetails = personResp.data?.results[0].FirstName+" "+personResp.data?.results[0].LastName
              setCustName(custpersondetails)
              setPersonData(personResp.data?.results[0]); //Person Data
              if (personResp.data?.results[0].personownergroups.length > 0) {
                const rmPersonemaildetails = personResp.data?.results[0].personownergroups[0].rmemployee.Email
                setRMEmail(rmPersonemaildetails); //RM email
                sessionStorage.setItem("RMEmail",rmPersonemaildetails)
                const rmPerson = personResp.data?.results[0].personownergroups[0].rmemployee?.person;
                if (rmPerson && rmPerson !== "null") {
                  context.setRMName(rmPerson.FirstName + " " + rmPerson.LastName); //RM Name
                }
              }
            }
          }
        }
        setLoading(false);
        return resp;
      // } catch (e) {
      //   console.log("error from GetInstance API", e);
      //   setLoading(false);
      //   history.push("/not-found");
      // }
    };
    let testOtp = sessionStorage.getItem("otpValidated")
    if(testOtp){
      getInstanceData(code);
    }
   else if (!context.isAuthenticated) {
      history.push("/login");
    } else {
      getInstanceData(code);
    }
  }, [code, context]);

  const portfolioref = useRef();
  const portfoliopreferenceref = useRef();
  const investmentbehaviourref = useRef();
  const suitabilityref = useRef();
  const personalinformationref = useRef();
  

  return isLoading ? (
    <Loading />
  ) : (
    <LandingPageContext.Provider
      value={{
        suitabilityValues,
        setSuitabilityValues,
        suitabilitySubmitBack,
        setSuitabilitySubmitBack
      }}
    >
      <Header 
      setPortfolioTouched={setPortfolioTouched}
      custname={custname}

       />
      <header className="gloss-header">
        <ul className="tabs-container">
          <li
            onClick={handleActiveTab.bind(this, "My Portfolio")}
            className={activeTab === "My Portfolio" ? "active-tab tab-item" : "tab-item"}
          >
            <FormattedMessage id="nav.myPortfolio" defaultMessage="nav.myPortfolio" />
          </li>
          <li
            onClick={handleActiveTab.bind(this, "Portfolio Preferences")}
            className={activeTab === "Portfolio Preferences" ? "active-tab tab-item" : "tab-item"}
            md={"auto"}
          >
            <FormattedMessage id="nav.portfolioPreferences" defaultMessage="nav.portfolioPreferences" />
          </li>
          <li
            onClick={handleActiveTab.bind(this, "Investment Behaviour")}
            className={activeTab === "Investment Behaviour" ? "active-tab tab-item" : "tab-item"}
            md={"auto"}
          >
            <FormattedMessage id="nav.investmentBehaviour" defaultMessage="nav.investmentBehaviour" />
          </li>
          <li
            onClick={handleActiveTab.bind(this, "Suitability")}
            className={activeTab === "Suitability" ? "active-tab tab-item" : "tab-item"}
            md={"auto"}
          >
            <FormattedMessage id="nav.suitability" defaultMessage="nav.suitability" />
          </li>
          {/* {!context.isClient && (
            <li
              onClick={handleActiveTab.bind(this, "Personal Information")}
              className={activeTab === "Personal Information" ? "active-tab tab-item" : "tab-item"}
              md={"auto"}
            >
              <FormattedMessage id="nav.personalInformation" defaultMessage="nav.personalInformation" />
            </li>
          )} */}
        </ul>
      </header>
      <div className="q-container">
        {activeTab === "My Portfolio" && (
          <MyPortfolio
            handleActiveTab={handleActiveTab}
            handleInvestAmount={handleInvestAmount}
            getFormValues={getFormValues}
            setFormValues={setFormValues}
            formikValues={formValues}
            isClient={context.isClient}
            ref={portfolioref}
          />
        )}
        {activeTab === "Portfolio Preferences" && (
          <PortfolioPreferences
            handleActiveTab={handleActiveTab}
            showTenthQuestion={convertToNumber(formValues["My Portfolio"].ques4) >= parseInt(10000000)}
            getFormValues={getFormValues}
            setFormValues={setFormValues}
            formikValues={formValues}
            currentTabName="Portfolio Preferences"
            isClient={context.isClient}
            ref={portfoliopreferenceref}
          />
        )}
        {activeTab === "Investment Behaviour" && (
          <InvestmentBehaviour
            handleActiveTab={handleActiveTab}
            getFormValues={getFormValues}
            setFormValues={setFormValues}
            formikValues={formValues}
            isClient={context.isClient}
            ref={investmentbehaviourref}
          />
        )}
        {activeTab === "Suitability" && (
          <Suitability
            handleSummeryModal={() => setSummeryModal(true)}
            handleActiveTab={handleActiveTab}
            getFormValues={getFormValues}
            setFormValues={setFormValues}
            formikValues={formValues}
            isClient={context.isClient}
            ref={suitabilityref}
          />
        )}
        {/* {activeTab === "Personal Information" && (
          <PersonalInformation
            handleSummeryModal={() => setSummeryModal(true)}
            handleActiveTab={handleActiveTab}
            getFormValues={getFormValues}
            setFormValues={setFormValues}
            formikValues={formValues}
            personData={personData}
            ref={personalinformationref}
          />
        )} */}
      </div>
      {summeryModal && (
        <Summery
          modalOpen={summeryModal}
          closeModal={() => setSummeryModal(false)}
          values={tempValues}
          getFormValues={getFormValues}
          setFormValues={setFormValues}
          formikValues={formValues}
          isClient={context.isClient}
          surveyId={code ? code : 0}
          setShow={setShow}
          setEmailShow={setEmailShow}
          emailshow={emailshow}
          rmEmail={rmEmail}
        />
      )}

      {show && <Modal show={show} />}
      {emailshow && <EmailContent />}  
      {/* <section className="no-data">
            <NotFound />
          </section> */}
    </LandingPageContext.Provider>
  );
};

export default LandingPage;
export { LandingPageContext };
