import React, { useEffect, useState } from "react";
import Info from "../../icons/Info.js";
import { FormattedMessage, useIntl } from "react-intl";
import "../LandingPage/LandingPage.css";
export default function ThankYouPage(props) {

  const status = props.location.state.status;
  const msg1 = "Your response is already submitted";
  const msg2 = "Your response is submitted successfully";
  const [msg, setMsg] = useState(msg1);

  useEffect(() => {
    if(status === "submitted") {
        setMsg(msg2);
    }

  }, [status]);


  return (
    <div style={{ width: "320px", margin: "0 auto", color: "#b99855", height: "100px", top: "30%", left: "0", right: "0", position: "absolute" }}>
      <div className="middle-box shadow" style={{ textAlign: "center" }}>
        <span >
          <Info color={"#b99855"} height={100} width={100} />
        </span>
        <h1 style={{ fontSize: "36px" }}>
          <b>
            <FormattedMessage id="thankyou.text1" defaultMessage="thankyou.text1" />
         
          </b>
        </h1>
        <h5 style={{ fontSize: "20px" }}>
          {/* {msg} */}
        </h5>
        <p>
          <FormattedMessage id="thankyou.text2" defaultMessage="thankyou.text2" />  
          <strong className="thankyoupagetextalignment"><FormattedMessage id="thankyou.text3" defaultMessage="thankyou.text3" />
          </strong> <span> <FormattedMessage id="thankyou.text4" defaultMessage="thankyou.text4" /></span>
         
        </p>
      </div>
    </div>
  );
}
