import { useContext } from "react";
import Routes from "../../components/Routes/Routes";
import "./App.css";
import {Context} from "../Wrapper/Wrapper";
import 'react-calendar/dist/Calendar.css';
function App() {
  const context = useContext(Context);
  return (
    <>
      <main className={context.locale === "ar"? "main-app rtl": "main-app"} >
        <Routes />
      </main>
    </>
  );
}

export default App;
