import React from "react";
import { FormattedMessage } from "react-intl";
import { LeftArrow } from "../../icons";
import {Row, Col} from "react-bootstrap";

export default function ButtonContainer({backAction,nextLabel}) {
    return (
        <Row style={{marginTop:60}}>
          <Col xs={5} sm={4} md={6} lg={2} >
            <button
                className="back-btn float-left-btn"
                onClick={backAction}
            >
                <span className="right-arrow">
                <LeftArrow
                  rotate={0}
                  color="#b99855"
                  width={12}
                  height={"auto"}
                />
              </span>
              <span className="left-arrow" style={{marginLeft:"5px"}}>
                <LeftArrow
                  rotate={180}
                  color="#b99855"
                  width={12}
                  height={"auto"}
                />
              </span>
                <FormattedMessage
                    id="portfolio.backbutton"
                    defaultMessage="portfolio.backbutton"
                />
            </button>
            </Col>
            <Col xs={7} sm={8} md={6} lg={10} >
            <button type="submit" className="nextButton goToInvestment float-right-btn">
                <FormattedMessage
                    id={nextLabel}
                    defaultMessage={nextLabel}
                />
                <span className="right-arrow" >
                <LeftArrow
                  rotate={180}
                  color="#b99855"
                  width={12}
                  height={"auto"}
                />
              </span>
              <span className="left-arrow"  style={{marginRight:"5px"}}>
                <LeftArrow
                  rotate={0}
                  color="#b99855"
                  width={12}
                  height={"auto"}
                />
              </span>
            </button>
            </Col>
            </Row>
    );
}