export const convertToNumber = (value) => {
  let numberValue = 0;
  if (value) {
    if (value === "0") {
      numberValue = 0;
    } else {
      numberValue = value.replace(/[^0-9]/g, "") - 0;
    }
  }
  return numberValue;
};
export const riskMapping1 = [
  {
    sumOfQuestion: 5,
    riskPlacement: 1,
    riskPercentage: "9%",
    riskCategory: "Conservative",
    riskCategoryvalue: "Low",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 6,
    riskPlacement: 1,
    riskPercentage: "9%",
    riskCategory: "Conservative",
    riskCategoryvalue: "Low",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 7,
    riskPlacement: 2,
    riskPercentage: "18%",
    riskCategory: "Conservative",
    riskCategoryvalue: "Low",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 8,
    riskPlacement: 2,
    riskPercentage: "18%",
    riskCategory: "Conservative",
    riskCategoryvalue: "Low",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 9,
    riskPlacement: 3,
    riskPercentage: "27%",
    riskCategory: "Conservative",
    riskCategoryvalue: "Low",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 10,
    riskPlacement: 3,
    riskPercentage: "27%",
    riskCategory: "Conservative",
    riskCategoryvalue: "Low",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 11,
    riskPlacement: 4,
    riskPercentage: "36%",
    riskCategory: "Moderately Conservative",
    riskCategoryvalue: "Low",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 12,
    riskPlacement: 4,
    riskPercentage: "36%",
    riskCategory: "Moderately Conservative",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 13,
    riskPlacement: 5,
    riskPercentage: "45%",
    riskCategory: "Moderately Conservative",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 14,
    riskPlacement: 5,
    riskPercentage: "45%",
    riskCategory: "Moderately Conservative",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 15,
    riskPlacement: 6,
    riskPercentage: "55%",
    riskCategory: "Moderately Aggressive",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 16,
    riskPlacement: 6,
    riskPercentage: "55%",
    riskCategory: "Moderately Aggressive",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 17,
    riskPlacement: 7,
    riskPercentage: "64%",
    riskCategory: "Moderately Aggressive",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 18,
    riskPlacement: 7,
    riskPercentage: "64%",
    riskCategory: "Moderately Aggressive",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 19,
    riskPlacement: 8,
    riskPercentage: "73%",
    riskCategory: "Moderately Aggressive",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 20,
    riskPlacement: 8,
    riskPercentage: "73%",
    riskCategory: "Moderately Aggressive",
    riskCategoryvalue: "Medium",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 21,
    riskPlacement: 9,
    riskPercentage: "82%",
    riskCategory: "Aggressive",
    riskCategoryvalue: "High",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 22,
    riskPlacement: 9,
    riskPercentage: "82%",
    riskCategory: "Aggressive",
    riskCategoryvalue: "High",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 23,
    riskPlacement: 10,
    riskPercentage: "91%",
    riskCategory: "Aggressive",
    riskCategoryvalue: "High",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 24,
    riskPlacement: 10,
    riskPercentage: "91%",
    riskCategory: "Aggressive",
    riskCategoryvalue: "High",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 25,
    riskPlacement: 11,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategoryvalue: "High",
    riskCategory_key: "Aggressive_Header",
  },
];
export const riskMapping2 = [
  {
    sumOfQuestion: 5,
    riskPlacement: 1,
    riskPercentage: "20%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 6,
    riskPlacement: 1,
    riskPercentage: "20%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 7,
    riskPlacement: 1,
    riskPercentage: "20%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 8,
    riskPlacement: 1,
    riskPercentage: "20%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 9,
    riskPlacement: 1,
    riskPercentage: "20%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 10,
    riskPlacement: 2,
    riskPercentage: "40%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 11,
    riskPlacement: 2,
    riskPercentage: "40%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 12,
    riskPlacement: 2,
    riskPercentage: "40%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 13,
    riskPlacement: 2,
    riskPercentage: "40%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 14,
    riskPlacement: 3,
    riskPercentage: "60%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 15,
    riskPlacement: 3,
    riskPercentage: "60%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 16,
    riskPlacement: 3,
    riskPercentage: "60%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 17,
    riskPlacement: 3,
    riskPercentage: "60%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 18,
    riskPlacement: 4,
    riskPercentage: "80%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 19,
    riskPlacement: 4,
    riskPercentage: "80%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 20,
    riskPlacement: 4,
    riskPercentage: "80%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 21,
    riskPlacement: 4,
    riskPercentage: "80%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 22,
    riskPlacement: 5,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 23,
    riskPlacement: 5,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 24,
    riskPlacement: 5,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 25,
    riskPlacement: 5,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
];
export const riskMapping3 = [
  {
    sumOfQuestion: 5,
    riskPlacement: 1,
    riskPercentage: "25%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 6,
    riskPlacement: 1,
    riskPercentage: "25%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 7,
    riskPlacement: 1,
    riskPercentage: "25%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 8,
    riskPlacement: 1,
    riskPercentage: "25%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 9,
    riskPlacement: 1,
    riskPercentage: "25%",
    riskCategory: "Conservative",
    riskCategory_key: "Conservative_Header",
  },
  {
    sumOfQuestion: 10,
    riskPlacement: 2,
    riskPercentage: "50%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 11,
    riskPlacement: 2,
    riskPercentage: "50%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 12,
    riskPlacement: 2,
    riskPercentage: "50%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 13,
    riskPlacement: 2,
    riskPercentage: "50%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 14,
    riskPlacement: 2,
    riskPercentage: "50%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 15,
    riskPlacement: 2,
    riskPercentage: "50%",
    riskCategory: "Moderately Conservative",
    riskCategory_key: "Moderately_Conservative_Header",
  },
  {
    sumOfQuestion: 16,
    riskPlacement: 3,
    riskPercentage: "75%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 17,
    riskPlacement: 3,
    riskPercentage: "75%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 18,
    riskPlacement: 3,
    riskPercentage: "75%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 19,
    riskPlacement: 3,
    riskPercentage: "75%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 20,
    riskPlacement: 3,
    riskPercentage: "75%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 21,
    riskPlacement: 3,
    riskPercentage: "75%",
    riskCategory: "Moderately Aggressive",
    riskCategory_key: "Moderately_Aggressive_Header",
  },
  {
    sumOfQuestion: 22,
    riskPlacement: 4,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 23,
    riskPlacement: 4,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 24,
    riskPlacement: 4,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
  {
    sumOfQuestion: 25,
    riskPlacement: 4,
    riskPercentage: "100%",
    riskCategory: "Aggressive",
    riskCategory_key: "Aggressive_Header",
  },
];
export const responsivnessMapping = [
  {
    responsivenessCategory: "Low",
    sumOfQuestion: 4,
    respPercentage: 27,
    responsiveness_category: "Cool Responder",
    responsiveness_category_key: "Cool_Responder_Header",
  },
  {
    responsivenessCategory: "Low",
    sumOfQuestion: 5,
    respPercentage: 27,
    responsiveness_category: "Cool Responder",
    responsiveness_category_key: "Cool_Responder_Header",
  },
  {
    responsivenessCategory: "Low",
    sumOfQuestion: 6,
    respPercentage: 27,
    responsiveness_category: "Cool Responder",
    responsiveness_category_key: "Cool_Responder_Header",

  },
  {
    responsivenessCategory: "Low",
    sumOfQuestion: 7,
    respPercentage: 27,
    responsiveness_category: "Cool Responder",
    responsiveness_category_key: "Cool_Responder_Header",
  },
  {
    responsivenessCategory: "Low",
    sumOfQuestion: 8,
    respPercentage: 27,
    responsiveness_category: "Cool Responder",
    responsiveness_category_key: "Cool_Responder_Header",
  },
  {
    responsivenessCategory: "Medium",
    sumOfQuestion: 9,
    respPercentage: 55,
    responsiveness_category: "Paced Responder",
    responsiveness_category_key: "Paced_Responder_Header",
  },
  {
    responsivenessCategory: "Medium",
    sumOfQuestion: 10,
    respPercentage: 55,
    responsiveness_category: "Paced Responder",
    responsiveness_category_key: "Paced_Responder_Header",
  },
  {
    responsivenessCategory: "Medium",
    sumOfQuestion: 11,
    respPercentage: 55,
    responsiveness_category: "Paced Responder",
    responsiveness_category_key: "Paced_Responder_Header",
  },
  {
    responsivenessCategory: "Medium",
    sumOfQuestion: 12,
    respPercentage: 55,
    responsiveness_category: "Paced Responder",
    responsiveness_category_key: "Paced_Responder_Header",
  },
  {
    responsivenessCategory: "Medium",
    sumOfQuestion: 13,
    respPercentage: 55,
    responsiveness_category: "Paced Responder",
    responsiveness_category_key: "Paced_Responder_Header",
  },
  {
    responsivenessCategory: "Medium",
    sumOfQuestion: 14,
    respPercentage: 55,
    responsiveness_category: "Paced Responder",
    responsiveness_category_key: "Paced_Responder_Header",
  },
  {
    responsivenessCategory: "Medium",
    sumOfQuestion: 15,
    respPercentage: 55,
    responsiveness_category: "Paced Responder",
    responsiveness_category_key: "Paced_Responder_Header",
  },
  {
    responsivenessCategory: "High",
    sumOfQuestion: 16,
    respPercentage: 95,
    responsiveness_category: "Fast Responder",
    responsiveness_category_key: "Fast_Responder_Header",
  },
  {
    responsivenessCategory: "High",
    sumOfQuestion: 17,
    respPercentage: 95,
    responsiveness_category: "Fast Responder",
    responsiveness_category_key: "Fast_Responder_Header",
  },
  {
    responsivenessCategory: "High",
    sumOfQuestion: 18,
    respPercentage: 95,
    responsiveness_category: "Fast Responder",
    responsiveness_category_key: "Fast_Responder_Header",
  },
  {
    responsivenessCategory: "High",
    sumOfQuestion: 19,
    respPercentage: 95,
    responsiveness_category: "Fast Responder",
    responsiveness_category_key: "Fast_Responder_Header",
  },
  {
    responsivenessCategory: "High",
    sumOfQuestion: 20,
    respPercentage: 95,
    responsiveness_category: "Fast Responder",
    responsiveness_category_key: "Fast_Responder_Header",
  },
];

