import axios from "axios";
import history from "./history";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
});


api.interceptors.request.use(async (config) => {

  const apimKey = process.env.REACT_APP_APIM_SUB_KEY; 

      if(apimKey){
          config.headers["Ocp-Apim-Subscription-Key"] = apimKey;
      }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.config.data &&
      error.config.data.indexOf("grant_type=refresh_token") > -1
    ) {
      return history.push("/logout?session=expired");
    }

    if (error.config.url === process.env.REACT_APP_LOGIN_ENDPOINT) {
      return Promise.reject(error);
    }

    if (401 === error.response.status) {
      if (window.location.pathname.indexOf("/landingPage") > -1) {
        return history.push("/logout?session=expired");
      }
    }

    return Promise.reject(error);
  }
);

export default api;
