import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App/App";
import reportWebVitals from "./reportWebVitals";
import { Router } from "react-router-dom";
import Wrapper from "./containers/Wrapper/Wrapper";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./history";
import { getConfig } from "./config";

const onRedirectCallback = (appState) => {
  // console.log("appState: ",appState);
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <Wrapper>
      <Router history={history}>
        <App />
      </Router>
    </Wrapper>
  </Auth0Provider>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
