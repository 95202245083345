import React, { useState, useEffect,useContext, forwardRef, useImperativeHandle } from "react";
import "./suitability.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ButtonContainer from "../../components/ButtonContainer/ButtonContainer";
import { getErrorDisplayRefToScrollIntoView, generateNumberArray } from "../../utils";
import { Row, Col } from "react-bootstrap";
import "./LandingPage.css";
import "./InvestmentBehaviour.css";
import { Form } from "react-bootstrap";
import { Context } from "../Wrapper/Wrapper";
const requiredError = () => {
  return (<FormattedMessage id="error.required" defaultMessage="error.required" />);
}

const InvestmentBehaviour = forwardRef((props,ref)=>{
  const context = useContext(Context);
  const intl = useIntl();
  const currentTabName = "Investment Behaviour";
  const { handleActiveTab, formikValues, setFormValues } = props;
  const currentTabVals = formikValues[currentTabName];
  const [selected, setSelected] = useState("");
  const [investment, setInvestment] = useState("");
  const [additionaldata, setadditionaldata] = useState(false);
  const [withdrawamountdata, setwithdrawamountdata] = useState(false);
  const [regularincomedata, setregularincomedata] = useState(false);
  const [remainingvalue1, setremainingvaluedata1] = useState("");
  const  InvestmentBehaviour = JSON.parse(sessionStorage.getItem('InvestmentBehaviour'))
  const scrollToTop = () => {
    let temp = document.getElementsByClassName("main-container");
   if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  };
  useEffect(()=>{
    let temp = document.getElementsByClassName("main-container");
     if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  },[])
  let remainingvalue = 0;
  const handleInvestAmount = (e) => {
    setInvestment(e.target.value);
  };
  const handleChange = (e) => {
    if(e.target.value.length === 0)
     {
      remainingvalue = 0
      setremainingvaluedata1(remainingvalue)
     }else{
     remainingvalue = remainingvalue + e.target.value.length
     setremainingvaluedata1(remainingvalue)
     }
}
  const genError = () => {
    return <FormattedMessage id="error.gen" defaultMessage="This field is required" />
  }
  const testdata = (event) => {
    const value = event.target.value;
    if (value === "yes") {
      setadditionaldata(true);
      setSelected("10");
      
      setClicked(true);
    } else {
      setSelected("13");
      
      setClicked(true);
      setadditionaldata(false);
    }
  };
  const withdrawamount = (event) => {
    const value = event.target.value;
    if (value === "yes") {
      setSelected("14");
      setClicked(true);
      setwithdrawamountdata(true);
    } else {
      setSelected("15");
      setClicked(true);
      setwithdrawamountdata(false);
    }
  };
  const regularincome = (event) => {
    const value = event.target.value;
    if (value === "yes") {
      setSelected("17");
      setClicked(true);
      setregularincomedata(true);
    } else {
      setSelected("18");
      setClicked(true);
      setregularincomedata(false);
    }
  };
  const [click, setClicked] = useState(false);

  let qNumbers = context.isClient ? generateNumberArray(10, 18): generateNumberArray(11, 19);

  const formik = useFormik({
    initialValues: {
      ques11: InvestmentBehaviour && InvestmentBehaviour.ques11 ? InvestmentBehaviour.ques11 : currentTabVals.ques11,
      ques12: InvestmentBehaviour && InvestmentBehaviour.ques12 ? InvestmentBehaviour.ques12 :currentTabVals.ques12,
      ques13: InvestmentBehaviour && InvestmentBehaviour.ques13 ? InvestmentBehaviour.ques13 :currentTabVals.ques13,
      ques14: InvestmentBehaviour && InvestmentBehaviour.ques14 ? InvestmentBehaviour.ques14 :currentTabVals.ques14,
      ques15: InvestmentBehaviour && InvestmentBehaviour.ques15 ? InvestmentBehaviour.ques15 :currentTabVals.ques15,
      ques16: InvestmentBehaviour && InvestmentBehaviour.ques16 ? InvestmentBehaviour.ques16 :currentTabVals.ques16,
      ques17: InvestmentBehaviour && InvestmentBehaviour.ques17 ? InvestmentBehaviour.ques17 :currentTabVals.ques17,
      ques18: InvestmentBehaviour && InvestmentBehaviour.ques18 ? InvestmentBehaviour.ques18 :currentTabVals.ques18,
      ques19: InvestmentBehaviour && InvestmentBehaviour.ques19 ? InvestmentBehaviour.ques19 :currentTabVals.ques19,
      otherNotes: InvestmentBehaviour && InvestmentBehaviour.otherNotes ? InvestmentBehaviour.otherNotes :currentTabVals.otherNotes
    },
    initialTouched: {
      ques11: true,
      ques12: true,
      ques13: true,
      ques14: true,
      ques15: true,
      ques16: true,
      ques17: true,
      ques18: true,
      ques19: true,
      otherNotes: true,
    },
    validationSchema: Yup.object().shape({
      ques11: Yup.string().required(genError),
      ques12: Yup.string().required(genError),
      ques13: Yup.string().required(genError),
      ques14: Yup.string().required(genError),
      ques15: Yup.string().required(genError),
      ques16: Yup.string().required(genError),
      ques17: Yup.string().required(genError),
      ques18: Yup.string().required(genError),
      ques19: Yup.string().required(genError),
      otherNotes: Yup.string().notRequired()
    }),
    onSubmit: (values) => {
      sessionStorage.setItem("InvestmentBehaviour",JSON.stringify(values))
      setFormValues(values);
      handleActiveTab("Suitability");
    },
    validateOnMount:true
  });
  useImperativeHandle(ref,()=>({ 
    validateInvestmentBehaviour:()=>{
       return {
         ...formik
       }
      },
      submitInvestmentBehaviour:()=>{
        return formik.submitForm();
      }
    
  }))
  useEffect(() => {
    if (formik.isSubmitting) {
      getErrorDisplayRefToScrollIntoView("validation-errorIn");
    }
  },[formik.isSubmitting])

  const setFormikValue = (e) => {
  
    formik.setFieldValue(e.target.name,e.target.value);
    
    
  }


  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off" >
      
      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="ib.heading" defaultMessage="ib.heading" />
        </p>
        

        <p className="q-text"><FormattedMessage id="q.eleven" defaultMessage="q.eleven" values={{qNumber:qNumbers[0]}}/></p>
        <div className="step-container arabictextalign">
          <Form.Check type="radio" id="q11" className="step-option">
            <Form.Check.Input type="radio" name="ques11" defaultChecked={formik.values.ques11 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q12" className="step-option">
            <Form.Check.Input type="radio" name="ques11" defaultChecked={formik.values.ques11 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q13" className="step-option">
            <Form.Check.Input type="radio" name="ques11" defaultChecked={formik.values.ques11 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q14" className="step-option">
            <Form.Check.Input type="radio" name="ques11" defaultChecked={formik.values.ques11 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt "><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q15" className="step-option">
            <Form.Check.Input type="radio" name="ques11" defaultChecked={formik.values.ques11 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques11 && formik.errors.ques11 ? (
          <div className="validation-errorIn">
            {formik.errors.ques11}
          </div>
        ) : ''}
      </div>
      
     
       <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="q.twelve" defaultMessage="q.twelve" values={{qNumber:qNumbers[1]}}/>
        </p>
        <div className="step-container">
          <Form.Check type="radio" id="q16" className="step-option">
            <Form.Check.Input type="radio" name="ques12" defaultChecked={formik.values.ques12 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q17" className="step-option">
            <Form.Check.Input type="radio" name="ques12" defaultChecked={formik.values.ques12 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q18" className="step-option">
            <Form.Check.Input type="radio" name="ques12" defaultChecked={formik.values.ques12 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q19" className="step-option">
            <Form.Check.Input type="radio" name="ques12" defaultChecked={formik.values.ques12 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q20" className="step-option">
            <Form.Check.Input type="radio" name="ques12" defaultChecked={formik.values.ques12 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /> </span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques12 && formik.errors.ques12 ? (
          <div className="validation-errorIn">
            {formik.errors.ques12}
          </div>
        ) : ''}
      </div>

      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="q.thriteen" defaultMessage="q.thriteen" values={{qNumber:qNumbers[2]}}/>
        </p>
        <div className="step-container">
          <Form.Check type="radio" id="q21" className="step-option">
            <Form.Check.Input type="radio" name="ques13" defaultChecked={formik.values.ques13 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q22" className="step-option">
            <Form.Check.Input type="radio" name="ques13" defaultChecked={formik.values.ques13 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q23" className="step-option">
            <Form.Check.Input type="radio" name="ques13" defaultChecked={formik.values.ques13 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q24" className="step-option">
            <Form.Check.Input type="radio" name="ques13" defaultChecked={formik.values.ques13 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q25" className="step-option">
            <Form.Check.Input type="radio" name="ques13" defaultChecked={formik.values.ques13 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /> </span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques13 && formik.errors.ques13 ? (
          <div className="validation-errorIn">
            {formik.errors.ques13}
          </div>
        ) : ''}
      </div>

      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="q.fourteen" defaultMessage="q.fourteen" values={{qNumber:qNumbers[3]}}/>
        </p>
        <div className="step-container">
          <Form.Check type="radio" id="q26" className="step-option">
            <Form.Check.Input type="radio" name="ques14" defaultChecked={formik.values.ques14 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q27" className="step-option">
            <Form.Check.Input type="radio" name="ques14" defaultChecked={formik.values.ques14 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q28" className="step-option">
            <Form.Check.Input type="radio" name="ques14" defaultChecked={formik.values.ques14 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q29" className="step-option">
            <Form.Check.Input type="radio" name="ques14" defaultChecked={formik.values.ques14 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q30" className="step-option">
            <Form.Check.Input type="radio" name="ques14" defaultChecked={formik.values.ques14 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /> </span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques14 && formik.errors.ques14 ? (
          <div className="validation-errorIn">
            {formik.errors.ques14}
          </div>
        ) : ''}
      </div>

      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="q.fifteen" defaultMessage="q.fifteen" values={{qNumber:qNumbers[4]}}/>
        </p>
        <div className="step-container">
          <Form.Check type="radio" id="q31" className="step-option">
            <Form.Check.Input type="radio" name="ques15" defaultChecked={formik.values.ques15 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q32" className="step-option">
            <Form.Check.Input type="radio" name="ques15" defaultChecked={formik.values.ques15 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q33" className="step-option">
            <Form.Check.Input type="radio" name="ques15" defaultChecked={formik.values.ques15 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q34" className="step-option">
            <Form.Check.Input type="radio" name="ques15" defaultChecked={formik.values.ques15 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q35" className="step-option">
            <Form.Check.Input type="radio" name="ques15" defaultChecked={formik.values.ques15 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /> </span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques15 && formik.errors.ques15 ? (
          <div className="validation-errorIn">
            {formik.errors.ques15}
          </div>
        ) : ''}
      </div>

      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="q.sixteen" defaultMessage="q.sixteen" values={{qNumber:qNumbers[5]}}/>
        </p>
        <div className="step-container">
          <Form.Check type="radio" id="q36" className="step-option">
            <Form.Check.Input type="radio" name="ques16" defaultChecked={formik.values.ques16 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q37" className="step-option">
            <Form.Check.Input type="radio" name="ques16" defaultChecked={formik.values.ques16 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q38" className="step-option">
            <Form.Check.Input type="radio" name="ques16" defaultChecked={formik.values.ques16 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q39" className="step-option">
            <Form.Check.Input type="radio" name="ques16" defaultChecked={formik.values.ques16 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q40" className="step-option">
            <Form.Check.Input type="radio" name="ques16" defaultChecked={formik.values.ques16 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /> </span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques16 && formik.errors.ques16 ? (
          <div className="validation-errorIn">
            {formik.errors.ques16}
          </div>
        ) : ''}
      </div>
      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="q.seventeen" defaultMessage="q.seventeen" values={{qNumber:qNumbers[6]}}/>
        </p>
        <div className="step-container">
          <Form.Check type="radio" id="q41" className="step-option">
            <Form.Check.Input type="radio" name="ques17" defaultChecked={formik.values.ques17 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q42" className="step-option">
            <Form.Check.Input type="radio" name="ques17" defaultChecked={formik.values.ques17 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q43" className="step-option">
            <Form.Check.Input type="radio" name="ques17" defaultChecked={formik.values.ques17 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q44" className="step-option">
            <Form.Check.Input type="radio" name="ques17" defaultChecked={formik.values.ques17 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q45" className="step-option">
            <Form.Check.Input type="radio" name="ques17" defaultChecked={formik.values.ques17 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques17 && formik.errors.ques17 ? (
          <div className="validation-errorIn">
            {formik.errors.ques17}
          </div>
        ) : ''}
      </div>
      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text">
        <FormattedMessage id="q.eighteen" defaultMessage="q.eighteen" values={{qNumber:qNumbers[7]}}/>
        </p>
        <div className="step-container">
          <Form.Check type="radio" id="q46" className="step-option">
            <Form.Check.Input type="radio" name="ques18" defaultChecked={formik.values.ques18 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q47" className="step-option">
            <Form.Check.Input type="radio" name="ques18" defaultChecked={formik.values.ques18 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q48" className="step-option">
            <Form.Check.Input type="radio" name="ques18" defaultChecked={formik.values.ques18 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q49" className="step-option">
            <Form.Check.Input type="radio" name="ques18" defaultChecked={formik.values.ques18 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q50" className="step-option">
            <Form.Check.Input type="radio" name="ques18" defaultChecked={formik.values.ques18 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques18 && formik.errors.ques18 ? (
          <div className="validation-errorIn">
            {formik.errors.ques18}
          </div>
        ) : ''}
      </div>
      <div style={{ marginBottom: "3rem" }}>
        <p className="q-text"><FormattedMessage id="q.ninteen" defaultMessage="q.ninteen" values={{qNumber:qNumbers[8]}}/></p>
        <div className="step-container">
          <Form.Check type="radio" id="q51" className="step-option">
            <Form.Check.Input type="radio" name="ques19" defaultChecked={formik.values.ques19 === "1"} value="1" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt marginleftn8"><FormattedMessage id="q.ans.stronglydisagree" defaultMessage="q.ans.stronglydisagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q52" className="step-option">
            <Form.Check.Input type="radio" name="ques19" defaultChecked={formik.values.ques19 === "2"} value="2" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.disagree" defaultMessage="q.ans.disagree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q53" className="step-option">
            <Form.Check.Input type="radio" name="ques19" defaultChecked={formik.values.ques19 === "3"} value="3" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.neutral" defaultMessage="q.ans.neutral" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q54" className="step-option">
            <Form.Check.Input type="radio" name="ques19" defaultChecked={formik.values.ques19 === "4"} value="4" onChange={setFormikValue} />
            <Form.Check.Label className="step-label" >
              <span className=" step-txt"><FormattedMessage id="q.ans.agree" defaultMessage="q.ans.agree" /></span>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="radio" id="q55" className="step-option">
            <Form.Check.Input type="radio" name="ques19" defaultChecked={formik.values.ques19 === "5"} value="5" onChange={setFormikValue} />
            <Form.Check.Label className="step-label">
              <span className="step-txt"> <FormattedMessage id="q.ans.stronglyagree" defaultMessage="q.ans.stronglyagree" /></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        {formik.touched.ques19 && formik.errors.ques19 ? (
          <div className="validation-errorIn">
            {formik.errors.ques19}
          </div>
        ) : ''}
      </div>

      <p className="q-text">
        
        <FormattedMessage id="ib.note" defaultMessage="ib.note" />
      </p>

      <div style={{ marginBottom: "3rem" }}>
        <Form.Control
          as="textarea"
          maxLength="200"
          name="textarea"
          placeholder={intl.formatMessage({
            description: "ib.note.placeholder",
            id: "ib.note.placeholder",
          })}
          defaultValue={formik.values.otherNotes}
          onChange ={(e) => 
            {
              formik.setFieldValue('otherNotes', e.target.value);
              handleChange(e);
            }
          }
          style={{
            width: "100%",
            height: "87px",
            backgroundColor: "#222",
            fontFamily: "Gotham",
            lineHeight: "22px",
            fontweight: 500,
            color: "#a1a1a1",
            boxShadow: "none",
            outline: "none",
            opacity: ".7",
            border: "none",
          }}
        />
        <div align="right">
          <span id="current_count">{`${remainingvalue1 === "" ? 0 : remainingvalue1 }`}</span>
          <span id="maximum_count">/ 200</span>
        </div>
      </div>  
      <ButtonContainer 
              backAction={()=>{
                formik.errors = {}          
                setFormValues(formik.values)
                handleActiveTab("Portfolio Preferences")
                scrollToTop();
                }} 
              nextLabel="btn.pfSummary2" />
    </form>
  );
})
export default InvestmentBehaviour;
