import React, { useState, useContext } from "react";
import { Container, Nav, Navbar, Button, Row, Col } from "react-bootstrap";
import { TFOIcon } from "../../icons";
import Info from "../../icons/Info.js";
import ConformationModal from "../../components/ConfirmationModal/ConfirmationModal";
import "./Header.css";
import { Context } from "../../containers/Wrapper/Wrapper";
import { FormattedMessage } from "react-intl";
export default function Header(props) {
  const context = useContext(Context);
  const [modalOpen, setModalOpen] = useState(true);
  const [hover, setHover] = useState(false);
  const handleHover = () => {
    setHover((pre) => !pre);
  };
  const handleModal = () =>{ 
    setModalOpen((prev) => !prev);
    props.setPortfolioTouched();
  }; 

  const modalContent = (
    <>
      <div className='modalcontent'>
        <p>
          <FormattedMessage
            id='header.modal.para1'
            defaultMessage='header.modal.para1'
          />{" "}
          <br />
          <FormattedMessage
            id='header.modal.para2'
            defaultMessage='header.modal.para2'
          />{" "}
          <br />
          <FormattedMessage
            id='header.modal.para3'
            defaultMessage='header.modal.para3'
          />
          <br />
        </p>
      </div>
    </>
  );
  return (
    <>
      <header className='main-header'>
        <Row className='header-row'>
          <Col className='logo '>
            <TFOIcon width='93px' />
          </Col>
          <Col className='header-col'>
            <h4 className='subheader'>
              {" "}
              <FormattedMessage
                id='header.mainTitle'
                defaultMessage='header.mainTitle'
              />
            </h4>
            <span className='miniheader miniheaderpaddingpri'>
              {" "}
              <FormattedMessage
                id='header.pncText'
                defaultMessage='header.pncText'
              />
              <span
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={handleModal}
              >
                <Info color={hover && "white"} />
              </span>
            </span>
          </Col>
        </Row>
        <Col className='profile-col'>
          {context.rmName && context.rmName !== "" && (
            <span className='miniheader miniheaderpaddingrel'>
              <FormattedMessage
                id='header.rmText'
                defaultMessage='header.rmText'
                values={{ rmName: context.rmName }}
              />
            </span>
          )}
          <span
            className='lang'
            
          >
          <span onClick={() => {
              const newLang = context.locale === "ar" ? "en" : "ar";
              context.selectLanguage(newLang);
            }}>
            <FormattedMessage id='header.lang' defaultMessage='header.lang' />
            </span>
          </span>
        </Col>
        <Row>
        <Col className='profile-coln'>
          {props.custname && props.custname !== "" && (
            <span className='miniheader miniheaderpaddingrel'>
              <FormattedMessage
                id='header.rmText2'
                defaultMessage='header.rmText2'
                values={{ custname: props.custname }}
              />
            </span>
          )}
          <Col className='profile-coln'>
            <span
            className='lang'
            
          >
          <span onClick={() => {
              const newLang = context.locale === "ar" ? "en" : "ar";
              context.selectLanguage(newLang);
            }}>
            <FormattedMessage id='header.lang' defaultMessage='header.lang' />
            </span>
          </span>
          </Col>
       </Col>
       </Row>
      </header>
      <ConformationModal
        showModal={modalOpen}
        closeCallback={handleModal}
        content={modalContent}
      >
        <Button
          variant='secondary'
          onClick={handleModal}
          className='agreeAndContinue'
        >
          <FormattedMessage
            id='header.modal.AgreeContinue'
            defaultMessage='header.modal.AgreeContinue'
          />
        </Button>
      </ConformationModal>
    </>
  );
}
