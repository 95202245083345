import React, { useContext, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "react-bootstrap";
import Tick from "../../icons/tick.jpeg";
import Cross from "../../icons/cross.svg";
import _ from "lodash";
import { Context } from "../Wrapper/Wrapper";
import {
  convertToNumber,
  riskMapping1,
  riskMapping2,
  responsivnessMapping,
  riskMapping3,
} from "./summery/helper.js";

const portfolioarr1 = [
  1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11,
];
const portfolioarr2 = [
  1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5,
];
const portfolioarr3 = [
  1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4,
];
const getMemoizedValues = (formikValues) => {
  if (!formikValues) return;
  let riskPlacementposition;
  let portfolioMaxValue;
  let riskPlacementValue;
  let risk_tolerance_category;
  let riskPlacementPercentage;
  let risk_tolerance;
  const invBeh =
    formikValues["Investment Behaviour"] &&
    formikValues["Investment Behaviour"].ques11
      ? formikValues["Investment Behaviour"]
      : JSON.parse(sessionStorage.getItem("InvestmentBehaviour"));

  const ques11 = invBeh && invBeh.ques11 ? invBeh.ques11 : "";
  const ques12 = invBeh && invBeh.ques12 ? invBeh.ques12 : "";
  const ques13 = invBeh && invBeh.ques13 ? invBeh.ques13 : "";
  const ques14 = invBeh && invBeh.ques14 ? invBeh.ques14 : "";
  const ques15 = invBeh && invBeh.ques15 ? invBeh.ques15 : "";
  const ques16 = invBeh && invBeh.ques16 ? invBeh.ques16 : "";
  const ques17 = invBeh && invBeh.ques17 ? invBeh.ques17 : "";
  const ques18 = invBeh && invBeh.ques18 ? invBeh.ques18 : "";
  const ques19 = invBeh && invBeh.ques19 ? invBeh.ques19 : "";
  const riskQuestionSum = _.sum([
    parseInt(ques11),

    parseInt(ques13),

    parseInt(ques15),
    parseInt(ques16),

    parseInt(ques18),
  ]);

  const ResponsivenessQuestionSum = _.sum([
    parseInt(ques12),
    parseInt(ques17),
    parseInt(ques14),
    parseInt(ques19),
  ]);

  const myPortfolio = formikValues["My Portfolio"];

  const ques4 = myPortfolio && myPortfolio.ques4 ? myPortfolio.ques4 : "";
  const ques5 = myPortfolio && myPortfolio.ques5 ? myPortfolio.ques5 : "";
  const ques6 = myPortfolio && myPortfolio.ques6 ? myPortfolio.ques6 : "";
  const ques3 = myPortfolio && myPortfolio.ques3 ? myPortfolio.ques3 : "";
  const ques7 = myPortfolio && myPortfolio.ques7 ? myPortfolio.ques7 : "";
  const ques5yr1 =
    myPortfolio && myPortfolio.ques5yr1 ? myPortfolio.ques5yr1 : "";
  const ques5yr2 =
    myPortfolio && myPortfolio.ques5yr2 ? myPortfolio.ques5yr2 : "";
  const ques7Amt =
    myPortfolio && myPortfolio.ques7Amt ? myPortfolio.ques7Amt : "";
  const ques5Year1 =
    myPortfolio && myPortfolio.ques5Year1 ? myPortfolio.ques5Year1 : "";
  const ques5Year2 =
    myPortfolio && myPortfolio.ques5Year2 ? myPortfolio.ques5Year2 : "";
  const ques5immediate =
    myPortfolio && myPortfolio.ques5immediate ? myPortfolio.ques5immediate : "";
  const ques6Year1 =
    myPortfolio && myPortfolio.ques6Year1 ? myPortfolio.ques6Year1 : "";
  const ques6Year2 =
    myPortfolio && myPortfolio.ques6Year2 ? myPortfolio.ques6Year2 : "";
  const range = ques4;

  const PortfolioPreferences = formikValues["Portfolio Preferences"];
  const portfolioarr1 = [
    1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11,
  ];
  const portfolioarr2 = [
    1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5,
  ];
  const portfolioarr3 = [
    1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4,
  ];

  const showTenthQuestion = convertToNumber(ques4) >= 10000000;

  const percent = (convertToNumber(ques7Amt) * 100) / convertToNumber(ques4);
  const percentFunded = isNaN(percent) ? "0" : percent.toFixed(2);
  let riskMapping =
    convertToNumber(range) >= 1000000
      ? riskMapping1
      : convertToNumber(range) >= 500000 && convertToNumber(range) <= 1000000
      ? riskMapping2
      : convertToNumber(range) >= 300000 && convertToNumber(range) <= 500000
      ? riskMapping3
      : [];

  const portfolioarr =
    convertToNumber(range) >= 1000000
      ? portfolioarr1
      : convertToNumber(range) >= 500000 && convertToNumber(range) <= 1000000
      ? portfolioarr2
      : convertToNumber(range) >= 300000 && convertToNumber(range) <= 500000
      ? portfolioarr3
      : null;

  for (let element of riskMapping) {
    if (element.sumOfQuestion === riskQuestionSum) {
      riskPlacementposition = element.riskPlacement;
      portfolioMaxValue = _.max(portfolioarr);
      riskPlacementValue = _.divide(riskPlacementposition / portfolioMaxValue);
      riskPlacementPercentage = _.round(_.multiply(riskPlacementValue, 100));
      risk_tolerance_category = element.riskCategory_key;
      risk_tolerance = element.riskPercentage;
    }
  }

  let assessmentWidth;
  let assessmentPosition;
  let riskval;

  if (riskPlacementPercentage >= 0 && riskPlacementPercentage <= 25) {
    assessmentWidth = riskPlacementPercentage;
    assessmentPosition = 1;
  } else if (riskPlacementPercentage > 25 && riskPlacementPercentage <= 50) {
    riskval = ((parseInt(riskPlacementPercentage) - 25) / 25) * 100;
    assessmentWidth = riskval;
    assessmentPosition = 2;
  } else if (riskPlacementPercentage > 50 && riskPlacementPercentage <= 75) {
    riskval = ((parseInt(riskPlacementPercentage) - 50) / 25) * 100;
    assessmentWidth = riskval;
    assessmentPosition = 3;
  } else if (riskPlacementPercentage > 75) {
    riskval = ((parseInt(riskPlacementPercentage) - 75) / 25) * 100;
    assessmentWidth = riskval;
    assessmentPosition = 4;
  }

  let responsiviness;
  let respPercentage;
  let responsiveness_category;
  let respval;

  for (let responsivinessvalue of responsivnessMapping) {
    if (responsivinessvalue.sumOfQuestion === ResponsivenessQuestionSum) {
      responsiviness = responsivinessvalue.responsivenessCategory;
      responsiveness_category = responsivinessvalue.responsiveness_category_key;
      respPercentage = responsivinessvalue.respPercentage;
    }
  }

  let responsivenessWidth;
  let responsivenessPosition;
  if (respPercentage >= 0 && respPercentage <= 33) {
    responsivenessWidth = respPercentage;
    responsivenessPosition = 1;
  } else if (respPercentage > 33 && respPercentage <= 66) {
    respval = ((parseInt(respPercentage) - 33) / 33) * 100;
    responsivenessWidth = respval;
    responsivenessPosition = 2;
  } else if (respPercentage > 66) {
    _.round(_.multiply(riskPlacementValue, 100));
    let respvalDivide = _.round(parseInt(respPercentage) - 66) / 33;
    let respval = _.multiply(respvalDivide, 100);
    responsivenessWidth = respval;
    responsivenessPosition = 3;
  }

  const point1 =
    convertToNumber(ques4) +
    convertToNumber(ques5Year1) +
    convertToNumber(ques5Year2);

  const nowPercentage =
    (convertToNumber(ques4) /
      (convertToNumber(ques4) +
        convertToNumber(ques5Year1) +
        convertToNumber(ques5Year2))) *
    100;
  const year1Percentage =
    (convertToNumber(ques5Year1) /
      (convertToNumber(ques4) +
        convertToNumber(ques5Year1) +
        convertToNumber(ques5Year2))) *
    100;
  const year2Percentage =
    (convertToNumber(ques5Year2) /
      (convertToNumber(ques4) +
        convertToNumber(ques5Year1) +
        convertToNumber(ques5Year2))) *
    100;
  const nowPercentageClient =
    (convertToNumber(ques5immediate) /
      (convertToNumber(ques5immediate) +
        convertToNumber(ques5Year1) +
        convertToNumber(ques5Year2))) *
    100;
  const year1PercentageClient =
    (convertToNumber(ques5Year1) /
      (convertToNumber(ques5immediate) +
        convertToNumber(ques5Year1) +
        convertToNumber(ques5Year2))) *
    100;
  const year2PercentageClient =
    (convertToNumber(ques5Year2) /
      (convertToNumber(ques5immediate) +
        convertToNumber(ques5Year1) +
        convertToNumber(ques5Year2))) *
    100;
  const totalval = convertToNumber(ques6Year1) + convertToNumber(ques6Year2);
  let point = 0;

  const ques10checkbox1 =
    PortfolioPreferences && PortfolioPreferences.ques10checkbox1
      ? PortfolioPreferences.ques10checkbox1
      : "";
  const ques10checkbox2 =
    PortfolioPreferences && PortfolioPreferences.ques10checkbox2
      ? PortfolioPreferences.ques10checkbox2
      : "";
  const ques10checkbox3 =
    PortfolioPreferences && PortfolioPreferences.ques10checkbox3
      ? PortfolioPreferences.ques10checkbox3
      : "";
  const ques10checkbox4 =
    PortfolioPreferences && PortfolioPreferences.ques10checkbox4
      ? PortfolioPreferences.ques10checkbox4
      : "";
  const ques10checkbox5 =
    PortfolioPreferences && PortfolioPreferences.ques10checkbox5
      ? PortfolioPreferences.ques10checkbox5
      : "";
  const ques10checkbox6 =
    PortfolioPreferences && PortfolioPreferences.ques10checkbox6
      ? PortfolioPreferences.ques10checkbox6
      : "";
  const ques9 =
    PortfolioPreferences && PortfolioPreferences.ques9
      ? PortfolioPreferences.ques9
      : "";

  return {
    invBeh,
    PortfolioPreferences,
    riskQuestionSum,
    ResponsivenessQuestionSum,
    range,
    ques10checkbox1,
    ques10checkbox2,
    ques10checkbox3,
    ques10checkbox4,
    ques10checkbox5,
    ques10checkbox6,
    showTenthQuestion,
    percent,
    percentFunded,
    riskMapping,
    portfolioarr,
    riskPlacementposition,
    portfolioMaxValue,
    riskPlacementValue,
    risk_tolerance_category,
    riskPlacementPercentage,
    risk_tolerance,
    assessmentWidth,
    assessmentPosition,
    riskval,
    responsiviness,
    respPercentage,
    responsiveness_category,
    respval,
    responsivenessWidth,
    responsivenessPosition,
    point1,
    nowPercentage,
    year1Percentage,
    year2Percentage,
    nowPercentageClient,
    year1PercentageClient,
    year2PercentageClient,
    totalval,
    myPortfolio,
    invBeh,
    showEmailcontent: true,
    ques6,
    ques9,
    ques6Year1,
    ques6Year2,
    ques4,
    ques7,
    ques7Amt,
    ques3,
    ques5Year1,
    ques5Year2,
    ques5,
    ques5immediate,
    ques5yr1,
    ques5yr2,
  };
};
export default function EmailContent({ formikValues }) {
  const [isClient, setisClient] = useState(false);
  const {
    myPortfolio,
    PortfolioPreferences,
    invBeh,
    ques10checkbox1,
    ques10checkbox2,
    ques10checkbox3,
    ques10checkbox4,
    ques10checkbox5,
    ques10checkbox6,
    showTenthQuestion,
    percentFunded,
    risk_tolerance_category,
    risk_tolerance,
    assessmentWidth,
    assessmentPosition,
    responsiveness_category,
    responsivenessWidth,
    responsivenessPosition,
    point1,
    nowPercentage,
    year1Percentage,
    year2Percentage,
    nowPercentageClient,
    year1PercentageClient,
    year2PercentageClient,
    totalval,
    showEmailcontent,
    ques6,
    ques9,
    ques6Year1,
    ques6Year2,
    ques4,
    ques7,
    ques7Amt,
    ques3,
    ques5Year1,
    ques5Year2,
    ques5,
    ques5immediate,
    ques5yr1,
    ques5yr2,
  } = useMemo(() => getMemoizedValues(formikValues), [formikValues]);
  return (
    <div style={{ display: "none" }}>
      <div className=" " id="mail">
        <h3 className=" ">
          <FormattedMessage
            id="header.smmarytext"
            defaultMessage="header.smmarytext"
          />
        </h3>
        <div>
          <ul>
            <li>
              {!isClient && (
                <div className=" ">
                  <FormattedMessage
                    id="firstpara.textpart1"
                    defaultMessage="firstpara.textpart1"
                  />
                  <span className=" ">
                    {isClient ? (
                      <FormattedMessage
                        id="firstpara.textadd"
                        defaultMessage="firstpara.textadd"
                      />
                    ) : (
                      <FormattedMessage
                        id="firstpara.textinvest"
                        defaultMessage="firstpara.texinvest"
                      />
                    )}
                  </span>{" "}
                  &nbsp;
                  <span className=" "></span>
                  {isClient ? (
                    <FormattedMessage
                      id="firstpara.textpartclient2"
                      defaultMessage="firstpara.textpartclient2"
                    />
                  ) : (
                    <FormattedMessage
                      id="firstpara.textpart2"
                      defaultMessage="firstpara.textpart2"
                    />
                  )}
                  &nbsp;
                  {ques5 === "yes" && ques4 ? (
                    <span className=" ">{"$" + point1.toLocaleString()}</span>
                  ) : (
                    <span className=" ">{"$" + point1.toLocaleString()}</span>
                  )}
                </div>
              )}
              {isClient && ques4 && (
                <div className=" ">
                  <FormattedMessage
                    id="firstpara.textpart1"
                    defaultMessage="firstpara.textpart1"
                  />

                  <span className=" ">
                    {isClient ? (
                      <FormattedMessage
                        id="firstpara.textadd"
                        defaultMessage="firstpara.textadd"
                      />
                    ) : (
                      <FormattedMessage
                        id="firstpara.textinvest"
                        defaultMessage="firstpara.texinvest"
                      />
                    )}
                  </span>
                  <span className=" "></span>
                  {isClient ? (
                    <FormattedMessage
                      id="firstpara.textpartclient2"
                      defaultMessage="firstpara.textpartclient2"
                    />
                  ) : (
                    <FormattedMessage
                      id="firstpara.textpart2"
                      defaultMessage="firstpara.textpart2"
                    />
                  )}

                  {ques5 === "yes" && ques4 ? (
                    <span className=" ">
                      {"$" + point1 && point1.toLocaleString()}
                    </span>
                  ) : (
                    <span className=" ">
                      {"$" + point1 && point1.toLocaleString()}
                    </span>
                  )}
                </div>
              )}
            </li>
            <li>
              {!isClient && (
                <div>
                  <p className=" ">
                    <FormattedMessage
                      id="secondpara.textpart1"
                      defaultMessage="secondpara.textpart1"
                    />
                  </p>
                </div>
              )}
              {isClient && ques4 && (
                <div>
                  <p className=" ">
                    <FormattedMessage
                      id="secondpara.textpart1"
                      defaultMessage="secondpara.textpart1"
                    />
                  </p>
                </div>
              )}
              {isClient && ques4 && (
                <div className=" ">
                  {ques5immediate !== 0 && ques5immediate && (
                    <div className=" ">
                      <div className=" ">
                        <div className=" ">
                          <div>
                            <FormattedMessage
                              id="secondpara.textpartheader"
                              defaultMessage="secondpara.textpartheader"
                            />
                          </div>
                        </div>
                        <p id="ques5immediate">${ques5immediate}</p>
                        <p>
                          {Number.parseFloat(nowPercentageClient).toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  )}
                  {ques5yr1 !== "" && ques5yr1 !== 0 && (
                    <>
                      <div className=" ">
                        <div className=" ">
                          <div className=" ">
                            <FormattedMessage
                              id="secondtextbox.header"
                              defaultMessage="secondtextbox.header"
                            />
                          </div>
                          <p>${ques5yr1}</p>
                          <p>
                            {Number.parseFloat(year1PercentageClient).toFixed(
                              2
                            )}
                            %
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {ques5yr2 !== "" && (
                    <>
                      <div className=" ">
                        <div className=" ">
                          <div className=" ">
                            <FormattedMessage
                              id="thirdtextbox.header"
                              defaultMessage="thirdtextbox.header"
                            />
                            &nbsp; - &nbsp;${ques5yr2}&nbsp;- &nbsp;{" "}
                            {Number.parseFloat(year2PercentageClient).toFixed(
                              2
                            )}
                            %
                          </div>
                          {/* <p>${myPortfolio.ques5yr2}</p>
                          <p>
                            {Number.parseFloat(year2PercentageClient).toFixed(
                              2
                            )}
                            %
                          </p> */}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {!isClient && (
                <div className=" ">
                  <div className=" ">
                    <div className=" ">
                      <div className=" ">
                        <div>
                          <FormattedMessage
                            id="secondpara.textpartheader"
                            defaultMessage="secondpara.textpartheader"
                          />
                          &nbsp; - &nbsp; ${ques4}&nbsp; - &nbsp;
                          {Number.parseFloat(nowPercentage).toFixed(2)}%
                        </div>
                      </div>
                    </div>
                  </div>
                  {((ques5 === "yes" &&
                    ques5Year1 &&
                    ques5Year1 !== undefined) ||
                    "" ||
                    null) && (
                    <>
                      <div className=" ">
                        <div className=" ">
                          <div className=" ">
                            <FormattedMessage
                              id="secondtextbox.header"
                              defaultMessage="secondtextbox.header"
                            />
                            &nbsp; -&nbsp;${ques5Year1} &nbsp;- &nbsp;
                            {Number.parseFloat(year1Percentage).toFixed(2)}%
                          </div>
                          {/* <p>${myPortfolio.ques5Year1}</p>
                          <p>
                            {Number.parseFloat(year1Percentage).toFixed(2)}%
                          </p> */}
                        </div>
                      </div>
                    </>
                  )}
                  {ques5 === "yes" && ques5Year2 !== "" && (
                    <>
                      <div className=" ">
                        <div className=" ">
                          <div className=" ">
                            <FormattedMessage
                              id="thirdtextbox.header"
                              defaultMessage="thirdtextbox.header"
                            />
                            &nbsp;- &nbsp;${ques5Year2}&nbsp;-&nbsp;
                            {Number.parseFloat(year2Percentage).toFixed(2)}%
                          </div>
                          {/* <p>${myPortfolio.ques5Year2}</p>
                            <p>
                              {Number.parseFloat(year2Percentage).toFixed(2)}%
                            </p> */}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {!isClient && (
                <div>
                  <p className=" ">
                    <FormattedMessage
                      id="secondpara.textpart2"
                      defaultMessage="secondpara.textpart2"
                    />
                    &nbsp;
                    <span className=" ">
                      <span>$</span>
                      {isClient ? ques5immediate : ques4}
                    </span>
                  </p>
                </div>
              )}
              {isClient && ques4 && (
                <div>
                  <p className=" ">
                    <FormattedMessage
                      id="secondpara.textpart2"
                      defaultMessage="secondpara.textpart2"
                    />
                    <span className=" ">
                      <span>$</span>
                      {isClient ? ques5immediate : ques4}
                    </span>
                  </p>
                </div>
              )}
            </li>
            <li>
              <div>
                {ques7 === "yes" ? (
                  <p className=" ">
                    <FormattedMessage
                      id="thridpara.text"
                      defaultMessage="thridpara.text"
                    />{" "}
                    <span className=" ">${ques7Amt}</span>
                    <span className=" ">
                      {" "}
                      <FormattedMessage
                        id="q.ans.errormessage7thsummary"
                        defaultMessage="q.ans.errormessage7thsummary"
                        values={{
                          summarypercentage: percentFunded,
                        }}
                      />
                    </span>
                  </p>
                ) : (
                  <p className=" ">
                    <FormattedMessage
                      id="thridpara.text"
                      defaultMessage="thridpara.text"
                    />{" "}
                    <span className=" ">
                      <FormattedMessage
                        id="thridpara.no"
                        defaultMessage="thridpara.no"
                      />
                    </span>
                  </p>
                )}
              </div>
            </li>
            <li>
              <div>
                <p className=" ">
                  <FormattedMessage
                    id="fourthpara.text"
                    defaultMessage="fourthpara.text"
                  />
                  &nbsp;
                  <span className=" ">
                    {ques3 && ques3 != "" && (
                      <FormattedMessage id={ques3} defaultMessage="" />
                    )}
                  </span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p className=" ">
                  <FormattedMessage
                    id="fifthpara.textpart1"
                    defaultMessage="fifthpara.textpart1"
                  />
                  &nbsp;
                  <span className=" "></span>
                  {ques9 === true ? (
                    <span className=" ">
                      <FormattedMessage
                        id="fifthpara.shariah"
                        defaultMessage="fifthpara.shariah"
                      />
                    </span>
                  ) : (
                    <span className=" ">
                      <FormattedMessage
                        id="fifthpara.conventional"
                        defaultMessage="fifthpara.conventional"
                      />
                    </span>
                  )}
                  <span className=" ">
                    &nbsp;
                    <FormattedMessage
                      id="fifthpara.portfolio"
                      defaultMessage="fifthpara.portfolio"
                    />
                  </span>
                </p>
              </div>
            </li>
            {/* <li> */}
            {ques6 === "yes" && (
              <>
                <li>
                  <div>
                    <p className=" ">
                      <FormattedMessage
                        id="sixthpara.first"
                        defaultMessage="sixthpara.first"
                      />
                      <span className=" ">
                        &nbsp;
                        <FormattedMessage
                          id="sixthpara.second"
                          defaultMessage="sixthpara.second"
                        />
                        {ques6Year2 && " & 2"}
                      </span>
                    </p>
                  </div>
                </li>
                <div>
                  {ques6Year1 && (
                    <div className=" ">
                      {/* <p> */}
                      <FormattedMessage
                        id="sixthbox.first"
                        defaultMessage="sixthbox.first"
                      />{" "}
                      &nbsp; - &nbsp;${ques6Year1}&nbsp;
                      {/* </p> */}
                      {/* <p>${ques6Year1}</p> */}
                    </div>
                  )}

                  {ques6Year2 && (
                    <div className=" ">
                      {/* <p> */}
                      <FormattedMessage
                        id="sixthbox.second"
                        defaultMessage="sixthbox.second"
                      />{" "}
                      &nbsp;-&nbsp;${ques6Year2}&nbsp;
                      {/* </p> */}
                      {/* <p>${myPortfolio.ques6Year2}</p> */}
                    </div>
                  )}

                  <div className=" " style={{ border: "none" }}>
                    <div className="">
                      {/* <p> */}
                      <FormattedMessage
                        id="sixthbox.total"
                        defaultMessage="sixthbox.total"
                      />{" "}
                      &nbsp;-&nbsp;${totalval.toLocaleString()}&nbsp;
                      {/* </p> */}
                    </div>
                    {/* <p>${totalval.toLocaleString()}</p> */}
                  </div>
                </div>
              </>
            )}
            {/* </li> */}
            {!isClient && showTenthQuestion && (
              <div>
                <li>
                  <p className=" ">
                    <FormattedMessage
                      id="sixthpara.textpart1"
                      defaultMessage="sixthpara.textpart1"
                    />
                    <span className=" "></span>
                    <span className=" ">
                      <FormattedMessage
                        id="sixthpara.textpart2"
                        defaultMessage="sixthpara.textpart2"
                      />
                    </span>
                  </p>
                </li>
              </div>
            )}
            {/* <li>
            {!isClient && showTenthQuestion && (
          <div>
            <p className=" ">
              <FormattedMessage
                id="sixthpara.textpart1"
                defaultMessage="sixthpara.textpart1"
              />
              <span className=" "></span>
              <span className=" ">
                <FormattedMessage
                  id="sixthpara.textpart2"
                  defaultMessage="sixthpara.textpart2"
                />
              </span>
            </p>
          </div>
        )}
            </li> */}
            {isClient ? (
              <div></div>
            ) : (
              <div>
                {showTenthQuestion && (
                  <div className=" ">
                    <div className=" ">
                      <FormattedMessage
                        id="seventh.header"
                        defaultMessage="seventh.header"
                      />
                    </div>
                    <ul>
                      <li>
                        <div className=" " id={!ques10checkbox1}>
                          <FormattedMessage
                            id="seventh.firstcheckboxtop"
                            defaultMessage="seventh.firstcheckboxtop"
                          />
                          &nbsp;-&nbsp;
                          <span className=" ">
                            <FormattedMessage
                              id="seventh.firstcheckboxtext"
                              defaultMessage="seventh.firstcheckboxtext"
                            />
                          </span>
                          &nbsp;-&nbsp;
                          <input
                            type="checkbox"
                            style={{ "accent-color": "white", color: "black" }}
                            id="ques10checkbox1"
                            name="ques10checkbox1"
                            checked={ques10checkbox1 ? ques10checkbox1 : false}
                          ></input>
                        </div>
                      </li>
                      <li>
                        <div className=" " id={!ques10checkbox2}>
                          <FormattedMessage
                            id="seventh.secondcheckboxtop"
                            defaultMessage="seventh.secondcheckboxtop"
                          />
                          &nbsp;-&nbsp;
                          <span className=" ">
                            <FormattedMessage
                              id="seventh.firstcheckboxtext"
                              defaultMessage="seventh.firstcheckboxtext"
                            />
                          </span>
                          &nbsp;-&nbsp;
                          <input
                            type="checkbox"
                            id="ques10checkbox2"
                            name="ques10checkbox2"
                            checked={ques10checkbox2 ? ques10checkbox2 : false}
                          ></input>
                        </div>
                      </li>
                      <li>
                        <div className=" " id={!ques10checkbox3}>
                          <FormattedMessage
                            id="seventh.secondcheckboxtop"
                            defaultMessage="seventh.secondcheckboxtop"
                          />
                          &nbsp;-&nbsp;
                          <span className=" ">
                            <FormattedMessage
                              id="seventh.thirdcheckboxtext"
                              defaultMessage="seventh.thirdcheckboxtext"
                            />
                          </span>
                          &nbsp;-&nbsp;
                          <input
                            type="checkbox"
                            id="ques10checkbox3"
                            name="ques10checkbox3"
                            checked={ques10checkbox3 ? ques10checkbox3 : false}
                          ></input>
                        </div>
                      </li>
                    </ul>
                    <div className=" ">
                      <FormattedMessage
                        id="seventh.header2"
                        defaultMessage="seventh.header2"
                      />
                    </div>
                    <ul>
                      <li>
                        <div className=" " id={!ques10checkbox4}>
                          <FormattedMessage
                            id="seventh.firstcheckboxtop"
                            defaultMessage="seventh.firstcheckboxtop"
                          />
                          &nbsp;-&nbsp;
                          <span className=" ">
                            <FormattedMessage
                              id="seventh.Realestatedebt"
                              defaultMessage="seventh.Realestatedebt"
                            />
                          </span>
                          &nbsp;-&nbsp;{" "}
                          <input
                            type="checkbox"
                            id="ques10checkbox4"
                            name="ques10checkbox4"
                            checked={ques10checkbox4 ? ques10checkbox4 : false}
                          ></input>
                        </div>
                      </li>
                      <li>
                        <div className=" " id={!ques10checkbox5}>
                          <FormattedMessage
                            id="seventh.firstcheckboxtop"
                            defaultMessage="seventh.firstcheckboxtop"
                          />{" "}
                          &nbsp;-&nbsp;
                          <span className=" ">
                            <FormattedMessage
                              id="seventh.fifthcheckboxtext"
                              defaultMessage="seventh.fifthcheckboxtext"
                            />
                          </span>
                          &nbsp;-&nbsp;
                          <input
                            type="checkbox"
                            id="ques10checkbox5"
                            name="ques10checkbox5"
                            checked={ques10checkbox5 ? ques10checkbox5 : false}
                          ></input>
                        </div>
                      </li>
                      <li>
                        <div className=" " id={!ques10checkbox6}>
                          <FormattedMessage
                            id="seventh.firstcheckboxtop"
                            defaultMessage="seventh.firstcheckboxtop"
                          />
                          &nbsp;-&nbsp;
                          <span className=" ">
                            {" "}
                            <FormattedMessage
                              id="seventh.thirdcheckboxtext"
                              defaultMessage="seventh.thirdcheckboxtext"
                            />
                          </span>
                          &nbsp;-&nbsp;
                          <input
                            type="checkbox"
                            id="ques10checkbox6"
                            name="ques10checkbox6"
                            checked={ques10checkbox6 ? ques10checkbox6 : false}
                          ></input>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </ul>
        </div>

        <h3 className="riskScore">
          <FormattedMessage
            id="header.summarytext2"
            defaultMessage="header.summarytext2"
          />
        </h3>
        <div style={{ fontSize: "18px" }}>
          <FormattedMessage
            id="subheader.summarytext1"
            defaultMessage="subheader.summarytext1"
          />
        </div>
        {/* <div className="cnt">
          <div className="progress1 progress_bar_start">
            <div
              className="progress1-bar progress_bar_start"
              style={
                assessmentPosition === 1
                  ? { width: `${assessmentWidth}%` }
                  : { width: "100%" }
              }
            ></div>
            {assessmentPosition === 1 ? (
              <div className="pointr">
                <div className="labelp">{risk_tolerance}</div>
              </div>
            ) : null}
          </div>

          <div className="progress1">
            <div
              className="progress1-bar"
              style={(() => {
                switch (assessmentPosition) {
                  case 3:
                  case 4:
                    return { width: "100%" };
                  case 2:
                    return { width: `${assessmentWidth}%` };
                  default:
                    return;
                }
              })()}
            ></div>
            {assessmentPosition === 2 ? (
              <div className="pointr">
                <div className="labelp">{risk_tolerance}</div>
              </div>
            ) : null}
          </div>

          <div className="progress1">
            <div
              className="progress1-bar"
              style={(() => {
                switch (assessmentPosition) {
                  case 4:
                    return { width: "100%" };
                  case 3:
                    return { width: `${assessmentWidth}%` };
                  default:
                    return;
                }
              })()}
            ></div>
            {assessmentPosition === 3 ? (
              <div className="pointr">
                <div className="labelp">{risk_tolerance}</div>
              </div>
            ) : null}
          </div>

          <div className="progress1 progress_bar_end">
            <div
              className="progress1-bar"
              style={(() => {
                switch (assessmentPosition) {
                  case 4:
                    return { width: `${assessmentWidth}%` };
                  default:
                    return;
                }
              })()}
            ></div>
            {assessmentPosition === 4 ? (
              <div className="pointr">
                <div className="labelp">{risk_tolerance}</div>
              </div>
            ) : null}
          </div>
        </div> */}

        <div>
          {/* <div style={{ fontSize: "12px" }}>
            <b>
              <FormattedMessage
                id="summary.conservative"
                defaultMessage="summary.conservative"
              />{" "}
            </b>
          </div> */}

          {/* <div className="textalignment" style={{ fontSize: "12px" }}>
            <b>
              <FormattedMessage
                id="summary.aggressive"
                defaultMessage="summary.aggressive"
              />
            </b>
          </div> */}
        </div>
        <div>
          {risk_tolerance_category && risk_tolerance_category != "" && (
            <div style={{ fontSize: "16px" }}>
              <FormattedMessage
                id={`${risk_tolerance_category}`}
                defaultMessage={`${risk_tolerance_category}`}
              />
            </div>
          )}
        </div>
        <Row>
          <Col size-lg="10" size-md="6" size-sm="12" size-xs="12">
            {risk_tolerance_category === "Conservative_Header" ? (
              <div>
                <FormattedMessage
                  id="summary.conservative.text"
                  defaultMessage="summary.conservative.text"
                />
              </div>
            ) : risk_tolerance_category === "Moderately_Conservative_Header" ? (
              <div>
                <FormattedMessage
                  id="summary.moderatelyconservative.text"
                  defaultMessage="summary.moderatelyconservative.text"
                />
              </div>
            ) : risk_tolerance_category === "Moderately_Aggressive_Header" ? (
              <div>
                <FormattedMessage
                  id="summary.moderatelyaggressive.text"
                  defaultMessage="summary.moderatelyaggressive.text"
                />
              </div>
            ) : risk_tolerance_category === "Aggressive_Header" ? (
              <div>
                <FormattedMessage
                  id="summary.aggressive.text"
                  defaultMessage="summary.aggressive.text"
                />
              </div>
            ) : null}
          </Col>
        </Row>
        <div style={{ fontSize: "18px" }}>
          <FormattedMessage
            id="subheader.summarytext2"
            defaultMessage="subheader.summarytext2"
          />{" "}
        </div>
        {/* <div className="cnt">
          <div className="progress1 progress_bar_start">
            <div
              className="progress1-bar progress_bar_start"
              style={
                responsivenessPosition === 1
                  ? { width: `${responsivenessWidth}%` }
                  : { width: "100%" }
              }
            ></div>
            {responsivenessPosition === 1 ? (
              <div className="pointr"></div>
            ) : null}
          </div>
          <div className="progress1">
            <div
              className="progress1-bar "
              style={(() => {
                switch (responsivenessPosition) {
                  case 3:
                    return { width: "100%" };
                  case 2:
                    return { width: `${responsivenessWidth}%` };
                  default:
                    return;
                }
              })()}
            ></div>
            {responsivenessPosition === 2 ? (
              <div ></div>
            ) : null}
          </div>
          <div className="progress1 progress_bar_end">
            <div
              className="progress1-bar"
              style={(() => {
                switch (responsivenessPosition) {
                  case 3:
                    return { width: `${responsivenessWidth}%` };
                  default:
                    return;
                }
              })()}
            ></div>
            {responsivenessPosition === 3 ? (
              <div ></div>
            ) : null}
          </div>
        </div> */}
        <div>
          {/* <div>
            <b>
              <FormattedMessage
                id="summary.calculated"
                defaultMessage="summary.calculated"
              />
            </b>
          </div> */}

          {/* <div >
            <b>
              <FormattedMessage
                id="summary.spontaneous"
                defaultMessage="summary.spontaneous"
              />
            </b>
          </div> */}
        </div>
        <div>
          {responsiveness_category && responsiveness_category != "" && (
            <div style={{ fontSize: "16px" }}>
              <FormattedMessage
                id={`${responsiveness_category}`}
                defaultMessage={`${responsiveness_category}`}
              />
            </div>
          )}
        </div>

        <div>
          {responsiveness_category === "Cool_Responder_Header" ? (
            <div>
              <FormattedMessage
                id="summary.coolResponder"
                defaultMessage="summary.coolResponder"
              />
            </div>
          ) : responsiveness_category === "Paced_Responder_Header" ? (
            <div>
              <FormattedMessage
                id="summary.pacedResponder"
                defaultMessage="summary.pacedResponder"
              />
            </div>
          ) : responsiveness_category === "Fast_Responder_Header" ? (
            <div>
              <FormattedMessage
                id="summary.fastResponder"
                defaultMessage="summary.fastResponder"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
// export default EmailContent;
