import React from "react";

export default function Info({color, height, width}) {
  return (
    <svg
      width={width ? width: 26}
      height={height ? height: 26}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      cursor='pointer'
    >
      <path
        d='M16 8C11.5887 8 8 11.5887 8 16C8 20.4113 11.5887 24 16 24C20.4113 24 24 20.4113 24 16C24 11.5887 20.4113 8 16 8ZM17.772 20.1893C17.3313 20.3627 15.804 21.092 14.924 20.3167C14.6613 20.086 14.5307 19.7933 14.5307 19.438C14.5307 18.7727 14.7493 18.1927 15.1433 16.8C15.2127 16.5367 15.2973 16.1953 15.2973 15.9247C15.2973 15.4573 15.12 15.3333 14.6393 15.3333C14.4047 15.3333 14.1447 15.4167 13.9093 15.5047L14.0393 14.972C14.564 14.7587 15.2227 14.4987 15.7867 14.4987C16.6327 14.4987 17.2553 14.9207 17.2553 15.7233C17.2553 15.9547 17.2153 16.36 17.1313 16.64L16.6447 18.3613C16.544 18.7093 16.362 19.4767 16.644 19.704C16.9213 19.9287 17.578 19.8093 17.902 19.6567L17.772 20.1893ZM16.968 13.3333C16.416 13.3333 15.968 12.8853 15.968 12.3333C15.968 11.7813 16.416 11.3333 16.968 11.3333C17.52 11.3333 17.968 11.7813 17.968 12.3333C17.968 12.8853 17.52 13.3333 16.968 13.3333Z'
        fill={color || "#6D8A96"}
      />
    </svg>
  );
}
