import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import ProtectedRoute from "../../containers/ProtectedRoute/ProtectedRoute";
import LandingPage from "../../containers/LandingPage/LandingPage";
import Login from "../../containers/Login/Login";
import NoMatch from "../../containers/Templates/NoMatch";
import { Container } from "react-bootstrap";
import ThankYouPage from "../../containers/Templates/ThankYouPage";

const Routes = () => {
  return (
    // <Switch>
    <div className="main-container">
      <Container>
        <div className="wrapper">
          <Switch>
            {/* <Route
                exact
                path="/prospect/:code"
                render={(props) => <LandingPage {...props} customer={false} />}
              />
              <Route
                exact
                path="/client/:code"
                render={(props) => <LandingPage {...props} customer={true} />}
              /> */}
            <Route
              exact
              path="/questionnaireForm/:code"
              render={(props) => <LandingPage {...props} />}
            />
            <Route
              exact
              path="/submited"
              component={ThankYouPage}
            />
            <Route
              path="/login"
              exact
              component={Login}
            />
            {/* <Route
              path="/"
            /> */}
            <Route path="/">
              <Redirect to="/not-found" />
              <NoMatch />
            </Route>
          </Switch>
        </div>
      </Container>
      {/* <ProtectedRoute exact path="/" component={Glossary} /> */}
    </div>
    // </Switch>
  );
};

export default Routes;
