const getErrorRefToScrollIntoView = (name) => {
    const errorRef = document.querySelector(`input[name=${name}]`);
    if (errorRef) {
      setTimeout(() => {
        errorRef.scrollIntoView({behavior:'smooth', block: 'nearest'});
      },100);
    }
  }

const getErrorDisplayRefToScrollIntoView = (name) => {
    const errorRef = document.querySelector(`div[class=${name}]`);
    if (errorRef) {
      setTimeout(() => {
        errorRef.scrollIntoView({behavior:'smooth', block: 'nearest'});
      },100);
    }
  }  

const generateNumberArray = (from, to) => {
  const numArray = [];
  for(let i = from; i <= to; i++){
    numArray.push(i+".");
  }
  return numArray;
}

  export {
    getErrorRefToScrollIntoView,
    getErrorDisplayRefToScrollIntoView,
    generateNumberArray
  }